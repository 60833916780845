import i18n from '@/plugins/i18n'

import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetReference } from '@/tt-widget-factory'
import {
  WidgetEditorEvents,
  WidgetExploreEvents,
  WidgetViewEnum,
} from '../types'
import { WidgetName } from '@/tt-widget-components/lib/names'

export type ConfirmUnarchveFn = (restorePermissions: boolean) => void

// export default class WidgetUnarchive extends WidgetBaseIntent {
export default class WidgetUnarchive extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_UNARCHIVE
  }

  /**
   * Trigger a form event
   * @param payload
   */
  async run(payload: WidgetReference) {
    const widgetType =
      payload.is === WidgetName.DASHBOARD_WIDGET
        ? WidgetViewEnum.DASHBOARD
        : WidgetViewEnum.WIDGET

    const showSuccessFeedback = () => {
      const message = i18n.tc(`intents.${widgetType}_archive.restored`)
      this.appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_SUCCESS,
        { message },
      )
    }

    const redirectToWidget = () => {
      this.appContext.eventManager.dispatchEvent(
        WidgetExploreEvents.ROUTE_TO_WIDGET,
        { widget: payload },
      )
    }

    const onUnarchiveSuccess = () => {
      showSuccessFeedback()
      redirectToWidget()
    }

    const showErrorFeedback = (e) => {
      console.error('Widget Unarchive Error', e)
      const message = i18n.t(`intents.${widgetType}_archive.failed_to_restore`)
      this.appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_ERROR,
        { message },
      )
    }

    const onConfirm: ConfirmUnarchveFn = (restorePermissions: boolean) => {
      this.widgetPersister
        .unarchive(payload, restorePermissions)
        .then(onUnarchiveSuccess)
        .catch(showErrorFeedback)
    }

    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
      is: 'WidgetUnarchiveDialog',
      title: i18n.tc(`intents.${widgetType}_archive.restore_title`),
      width: 400,
      props: {
        message: i18n.tc(`intents.${widgetType}_archive.restore_this_q`),
        onConfirm,
        widget: payload,
      },
    })
  }
}
