<template>
  <div>
    <WidgetSearchResults
      view-mode="CARDS"
      :search-provider="searchProvider"
      @click="click($event)"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { WidgetCollectionSearchProviderInterface } from '@/tt-widget-factory/services/widget-collections/types'
import { CategoryNames } from '@/apps/app.tracktik.insights.studio/types'
import { DialogInterface, LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetStoreModel } from '@/tt-widget-factory'

export default Vue.extend({
  name: 'TutorialBanner',
  computed: {
    searchProvider(): WidgetCollectionSearchProviderInterface {
      return {
        categoryId: CategoryNames.TUTORIALS,
      }
    },
  },
  methods: {
    click(widget: WidgetStoreModel) {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        is: 'WidgetPage',
        title: widget.title,
        width: 1000,
        height: 600,
        props: {
          height: 600,
          widget: widget.widget,
        },
      } as DialogInterface)
    },
  },
})
</script>
