import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import { WidgetViewTags } from '@/tt-widget-factory/services/widget-collections/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import i18n from '@/plugins/i18n'

export default class WidgetUnstar extends WidgetBaseIntent {
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_UNSTAR
  }

  run(payload: any): any {
    this.appContext.eventManager.dispatchEvent(WidgetEditorEvents.WIDGET_TAG, {
      tag: WidgetViewTags.STARRED,
      uid: payload.uid,
      remove: true,
    })
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.SNACK_INFO, {
      message: i18n.t('intents.widget_unstar.snack_info_message'),
    })
  }
}
