<template>
  <div>
    <v-toolbar flat color="transparent" dense class="mb-0 pb-0">
      <v-subheader style="width: 100%">
        <slot name="title"><span v-text="$t(title)" /></slot>
      </v-subheader>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div class="pl-6 pr-6">
      <slot></slot>
      <div class="text-right">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ExploreSection',
  provide() {
    return {
      showSharedIcon: false,
    }
  },
  props: {
    title: String,
  },
})
</script>
