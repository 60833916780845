<template>
  <v-card v-if="isAuthorized">
    <v-toolbar dense flat color="#fafafa">
      <v-app-bar-nav-icon>
        <v-icon color="orange">insert_chart</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="ml-3">Reports Json Schema Demo</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row class="px-3">
        <v-col>
          <EntitySelectorField
            v-model="reportId"
            resource="reports"
            v-bind="{ queryOptions }"
            solo
          />
          <ProvideCompoundFormManager @ready="setFormManager">
            <div v-if="schema && formManager" class="pa-6">
              <JsonForm
                :schema="schema"
                :form-options="formOptions"
                :show-status="true"
                :value="formManager.getFormValue(formId)"
                @input="formManager.setFormValue(formId, $event)"
                @errors="formManager.setFormErrors(formId, $event)"
                @valid="formManager.setFormErrors(formId, {})"
              />
              <v-btn v-if="showSubmit" color="success" @click="submit">
                SUBMIT
              </v-btn>
            </div>
          </ProvideCompoundFormManager>
        </v-col>
        <v-col>
          <v-btn
            v-if="hasModelProperties"
            class="mb-3"
            outlined
            small
            @click="clear"
          >
            Clear model
          </v-btn>

          <WidgetJsonViewer v-if="schema" :json="model" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
  <v-container v-else>You don't have permission.</v-container>
</template>

<script lang="ts">
import Vue from 'vue'

import {
  EmptyValueRule,
  JsonForm,
  FormOptions,
} from '@tracktik/tt-json-schema-form'

import WidgetJsonViewer from '@/tt-widget-components/components/WidgetJsonViewer.vue'
import { AppContext } from '@/tt-app-context'
import { CollectionQuery } from '@/tt-widget-components'
import { FilterOperatorType } from '@/tt-widget-factory'

import EntitySelectorField from '../components/EntitySelectorField.vue'
import { createEntityViewDefinitions } from '../EntityViewDefinitions'
import { CompoundFormManager } from '@/tt-app-layout'
import ProvideCompoundFormManager from '@/tt-app-layout/components/ProvideCompoundFormManager.vue'
import { DatePresetValue } from '@/tt-entity-filter/temporal-filters/date-presets'

export default Vue.extend({
  name: 'ReportsJsonSchemaDemo',
  components: {
    JsonForm,
    WidgetJsonViewer,
    EntitySelectorField,
    ProvideCompoundFormManager,
  },
  data() {
    const definitions = createEntityViewDefinitions(
      this.$appContext.authModule.getUserPreferences(),
    )
    return {
      reportId: null,
      definitions,
      schema: null,
      formOptions: null as FormOptions,
      callback: null,
      showSubmit: false,
      formManager: null as CompoundFormManager | null,
    }
  },
  computed: {
    isAuthorized(): boolean {
      return this.$appContext.authModule.hasPermission('superuser:*')
    },
    model(): Record<string, unknown> {
      return this.formManager?.getCompoundValue() || {}
    },
    hasModelProperties(): boolean {
      return Object.keys(this.model).length > 0
    },
    formId(): string {
      return this.$options.name
    },
    queryOptions: (): Partial<CollectionQuery> => ({
      filters: [
        {
          attribute: 'submittedOn',
          operator: FilterOperatorType.BETWEEN,
          value: DatePresetValue.ThisYear,
        },
      ],
    }),
  },
  watch: {
    reportId: {
      handler(reportId) {
        this.schema = null
        this.formOptions = null
        this.callback = null
        if (reportId && this.formManager) {
          this.fetchReport(reportId)
        }
      },
      immediate: true,
    },
  },
  methods: {
    setFormManager(formManager: CompoundFormManager): void {
      this.formManager = formManager
      if (this.reportId) {
        this.fetchReport(this.reportId)
      }
    },
    fetchReport(id) {
      const appContext: AppContext = this.$appContext

      const setState = ({ jsonFormSchema }) => {
        console.log(jsonFormSchema)
        const { schema, formOptions, values, callback } = jsonFormSchema
        const { reportTemplate } = values // we clear all null values received -- API should send empty payload instead of null

        const definitions = { ...this.definitions, ...formOptions.definitions }

        this.schema = schema
        // we need to keep Empty Values because the API does not have a real PUT method:
        // any missing property will be ignored by the API, thus we need to explicitly set property to null or empty
        this.formOptions = { definitions, emptyValues: EmptyValueRule.KEEP }
        this.formManager?.setFormValue(this.formId, { reportTemplate })
        this.callback = callback
      }

      appContext.authModule
        .getApi()
        .get('reports', id, { extension: ['jsonFormSchema'] })
        .then(setState)
        .catch(console.warn)
    },
    submit() {
      const appContext: AppContext = this.$appContext

      appContext.authModule
        .getApi()
        .edit('reports', this.reportId, this.model)
        .then(console.log)
        .catch(console.warn)
    },
    clear() {
      this.formManager?.clearValue()
    },
  },
})
</script>
