<template>
  <div class="widgetMinHeight">
    <WidgetContainer>
      <div class="d-flex fill-width fill-height">
        <ResizeBox :start-width="startWidth">
          <div class="data-container">
            <DataDoc />
          </div>
        </ResizeBox>
        <div class="tql-container">
          <WidgetContextToolbar
            widget-type="TQLQueryWidget"
            :show-title="false"
            :hook="hook"
          >
            <template slot="left">
              <v-icon class="mr-4"> insert_chart </v-icon>
              {{ $t('tql_query_view.tql_editor') }}
            </template>
            <template slot="toggle">
              <IncludeInactiveInput
                v-model="includeInactive"
                :show-toggle-only="true"
              />
            </template>
          </WidgetContextToolbar>
          <TqlEditor v-model="tql" @valid="receiveTQLValidation($event)">
            <template #toolbar>
              <v-menu
                v-model="displaySaveMenu"
                :nudge-width="320"
                :close-on-content-click="false"
              >
                <template #activator="{ on }">
                  <v-btn
                    :disabled="!tqlIsValid"
                    class="mr-3"
                    :dark="!tqlIsValid"
                    small
                    v-on="on"
                  >
                    <span v-text="$t('tql_query_view.save_as_widget')" />
                  </v-btn>
                </template>
                <v-card class="pa-0">
                  <v-card-text class="pl-3 pr-3 pt-0 pb-0">
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="name"
                          hide-details
                          dense
                          :offset-x="30"
                          :offset-y="30"
                          append-icon="mdi-folder"
                          append-icon-color="orange"
                          filled
                          :label="$t('tql_query_view.enter_widget_title')"
                          clearable
                          @keydown.enter="create"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-toolbar flat dense>
                    <v-btn text color="grey" @click="closeSaveMenu">
                      <span v-text="$t('tql_query_view.cancel')" />
                    </v-btn>
                    <v-spacer />
                    <v-btn text color="green" :disabled="!name" @click="create">
                      <span v-text="$t('tql_query_view.save')" />
                    </v-btn>
                  </v-toolbar>
                </v-card>
              </v-menu>
            </template>
          </TqlEditor>
          <widget-factory
            v-if="widget"
            :widget="widget"
            @update:hook="hook = $event"
          />
        </div>
      </div>
    </WidgetContainer>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { TQLQueryWidgetModel } from '@/tt-widget-components'
import DataDoc from '../components/DataDoc.vue'
import TqlEditor from '@/tt-tql-inputs/src/components/TqlEditor.vue'
import { cloneDeep } from 'lodash'
import WidgetPersister from '../lib/WidgetPersister'
import { SaveTQLAsWidget } from '@/plugins/o11n'
import { WidgetExploreEvents } from '../types'
import ResizeBox from '../../../tt-app-layout/components/ResizeBox.vue'
import TQLQueryWidgetHook from '@/tt-widget-components/widgets/TQLQuery/TQLQueryWidgetHook'

export default Vue.extend({
  name: 'TQLQueryView',
  components: {
    DataDoc,
    TqlEditor,
    ResizeBox,
  },
  data() {
    return {
      dirty: true,
      widget: null,
      name: null,
      tql: 'SELECT company, customId, firstName, lastName from clients',
      displaySaveMenu: false,
      includeInactive: false,
      tqlIsValid: true,
      hook: null as null | TQLQueryWidgetHook,
    }
  },
  computed: {
    startWidth(): number {
      return this.$vuetify.breakpoint.smAndUp ? 25 : 40
    },
    validWidget(): TQLQueryWidgetModel {
      if (!this.tql) {
        return
      }

      return {
        is: 'TQLQueryWidget',
        dataSet: {
          tql: this.tql,
          ignoreValidation: true,
          includeInactive: this.includeInactive,
        },
      } as TQLQueryWidgetModel
    },
  },
  watch: {
    validWidget() {
      this.widget = this.validWidget
    },
  },
  created() {
    if (this.validWidget) {
      this.widget = cloneDeep(this.validWidget)
    }
  },
  methods: {
    receiveTQLValidation(event: boolean) {
      this.tqlIsValid = event
    },
    closeSaveMenu() {
      this.displaySaveMenu = false
    },
    create() {
      this.$analytics.track(SaveTQLAsWidget.create())
      const widget = { ...this.validWidget, title: this.name }
      const widgetPersister = new WidgetPersister(this.$appContext)

      const routeToWidget = (widget) => {
        this.$eventManager.dispatchEvent(WidgetExploreEvents.ROUTE_TO_WIDGET, {
          widget,
        })
      }

      widgetPersister.create(widget).then(routeToWidget)
    },
    update() {
      this.widget = this.validWidget
      this.dirty = false
    },
  },
})
</script>
<style scoped>
.widgetMinHeight {
  height: calc(100% - 48px);
  min-height: 900px;
}
.tql-container {
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 0;
  flex-direction: column;
}
.data-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
</style>
