import { JSONSchema7 } from '@tracktik/tt-json-schema-form'
import { definitionNames } from '../EntityViewDefinitions.utils'

/**
 * Kitchen Sink Model
 */
export function createKitchenSinkJsonSchema(): JSONSchema7 {
  const createSchemaPropertiesFromTypes = (acc, name) => ({
    ...acc,
    [name]: {
      title: `${name.replace(/([A-Z])/g, ' $1')}`,
      description: name,
      $ref: `#/definitions/${name}`,
    },
  })
  const typesProperties = definitionNames.reduce(
    createSchemaPropertiesFromTypes,
    {},
  )

  const Enum = {
    ...typesProperties['Enum'],
    enum: ['Option A', 'Option B', 'Option C'],
  }

  const RegionsId = {
    description: 'Relation to regions',
    $ref: '#/definitions/Relation',
    'x-resource': 'regions',
  }

  const Relation = {
    description: 'Relation to clients',
    $ref: '#/definitions/Relation',
    'x-resource': 'clients',
  }

  const RelationShift = {
    description: 'Relation to shifts',
    $ref: '#/definitions/Relation',
    'x-resource': 'shifts',
  }

  const RelationInvoiceLineTaxes = {
    description: 'Relation to invoice-line-taxes',
    $ref: '#/definitions/Relation',
    'x-resource': 'invoice-line-taxes',
  }

  const RelationList = {
    description: 'RelationList to regions',
    $ref: '#/definitions/RelationList',
    'x-resource': 'regions',
  }

  const RelationListClients = {
    description: 'RelationList to clients',
    $ref: '#/definitions/RelationList',
    'x-resource': 'clients',
  }

  return {
    title: 'Some title',
    type: 'object',
    properties: {
      ...typesProperties,
      Enum,
      Relation,
      RegionsId,
      RelationShift,
      RelationInvoiceLineTaxes,
      RelationList,
      RelationListClients,
    },
    definitions: {
      TimeStampNumber: { type: 'string' },
      FileImage: { type: 'number', format: 'image' },
      TimeZone: { type: 'string' },
      Boolean: { type: 'boolean' },
      PhoneNumber: { type: 'string' },
      Password: { type: 'string', format: 'password' },
      TimeStampDate: { type: 'string' },
      Date: { type: 'string', format: 'date' },
      DateTime: { type: 'string', format: 'date-time' },
      Email: { type: 'string', format: 'email' },
      Uri: { type: 'string', format: 'uri' },
      Label: { type: 'string' },
      TextArea: { type: 'string' },
      Time: { type: 'string' },
      Currency: { type: 'number' },
      CurrencyRate: { type: 'number' },
      Number: { type: 'number' },
      SortIndex: { type: 'integer' },
      Hours: { type: 'number' },
      Minutes: { type: 'number' },
      Decimal: { type: 'number' },
      GeoRegion: { type: 'string', minLength: 2, maxLength: 2 },
      GeoCountry: { type: 'string', minLength: 2, maxLength: 2 },
      GeoPostalCode: { type: 'string' },
      Relation: { type: 'integer' },
      GeoPosition: { type: 'number' },
      GeoPositionLatitude: { type: 'number' },
      GeoPositionLongitude: { type: 'number' },
      Enum: { type: 'string' },
      Percentage: { type: 'number' },
      RelationList: { type: 'string' },
      // @ts-ignore -- wrong typing on additionalProperties
      JSON: { type: 'object', additionalProperties: true },
      CSV: { type: 'array', items: { type: 'string' } },
      Integer: { type: 'number' },
      Signature: { type: 'integer', format: 'image' },
      DrawCanvas: { type: 'integer', format: 'image' },
    },
  }
}
