import AIAssistantAPI from "@/apps/app.tracktik.insights.studio/lib/AssistantAPI";
import {ChatMessage} from "@/tt-app-extensions/chat-bot/types";
import Vue from 'vue'
import {AppContext} from "@/tt-app-context";
import {LayoutWindowEvent} from "@/tt-app-layout";

export interface ChatAgent {
  id: string;
  name: string;
  avatar: string;
  title: string;
  welcome: string;
  nextQuestions?: string[];
}

export interface Conversation {
  context?: any
  messages: ChatMessage[];
  status: ConversationStatus
  thread?: string;
}

export enum ConversationStatus {
  New = 'new',
  Idle = 'idle',
  Loading = 'loading',
  Error = 'error',
}

export interface AnalysePayload {
  question?: string,
  tql?: string,
  data: any[]
}

export enum ChatRole {
  User = 'user',
  Assistant = 'assistant',
}

export default class ChatSession {

  chatAgent: ChatAgent
  conversation: Conversation
  appContext: AppContext

  constructor(appContext: AppContext, chatAgent: ChatAgent, conversation: Conversation) {
    this.appContext = appContext
    this.chatAgent = Vue.observable(chatAgent)
    this.conversation = Vue.observable(conversation)
  }

  get messages(): ChatMessage[] {
    return this.conversation.messages
  }

  set messages(messages: ChatMessage[]) {
    this.conversation.messages = messages
  }

  get status(): ConversationStatus {
    return this.conversation.status
  }

  get title(): string {
    return this.chatAgent.title
  }

  start() {
    if (!this.messages.length) {
      this.messages.push({
        role: 'assistant',
        message: this.chatAgent.welcome,
        nextQuestions: this.chatAgent.nextQuestions?? []
      })
    }
  }

  get nextQuestions(): string[] {

    if (!this.messages.length) {
      return [];
    }
    const lastMessage:ChatMessage = this.messages[this.messages.length - 1];
    if (lastMessage.role === 'assistant' && (lastMessage?.nextQuestions ?? false)) {
      return lastMessage.nextQuestions ?? []
    }
    return []
  }

  async stop() {
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.SNACK_INFO, {
      message: 'Chat session ended',
      dismissTime: 2000,
    });
  }

  async like(message: ChatMessage) {
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
      message: 'Thanks for the feedback!',
      dismissTime: 2000,
    });
  }


  async dislike(message: ChatMessage) {
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.SNACK_INFO, {
      message: 'Thanks for the feedback!',
      dismissTime: 2000,
    });
  }

  async love(message: ChatMessage) {
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
      message: 'I added this to your favorites!',
      dismissTime: 2000,
    });
  }

  async analyse(data: any[], tql: string) {

    return this.ask('Analyse this data', {
      tql: tql,
      data: data,

    })
  }

  snack(message: string) {
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.SNACK_INFO, {
      message: message,
      dismissTime: 2000,
    });
  }
  async ask(question: string, data: AnalysePayload = null) {

    this.conversation.status = ConversationStatus.Loading

    this.messages.push({
      role: ChatRole.User,
      message: question,
    })

    const payload = {
      message: question,
      thread: this.conversation.thread ?? null,
      context: this.conversation.context,
      data: data ?? null,
    }

    const response = await AIAssistantAPI.fetch(this.chatAgent.id, payload).catch(() => {
      this.conversation.status = ConversationStatus.Error
      return
    })

    // Set the thread for the next message
    if (response.thread ?? null) {
      this.conversation.thread = response.thread
    }

    this.messages = Vue.observable([...this.messages, {...response, question: question}])
    this.conversation.status = ConversationStatus.Idle

  }

}
