import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import { WidgetCategoryModel } from '@/tt-widget-factory'
import i18n from '@/plugins/i18n'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import { createFormOptions, createJsonSchema } from './CategoryJsonSchema'
import { WidgetCategoryPayload } from '@/tt-widget-factory/WidgetCategoryModel'

const createValidOptions = (categoryModel: WidgetCategoryModel) => {
  const { aclRule, id, ownedBy, ...options } = categoryModel.meta
  return options
}
export default class CategoryEdit extends WidgetBaseIntent {
  getEventName(): string {
    return WidgetEditorEvents.CATEGORY_EDIT
  }

  run(categoryModel: WidgetCategoryModel): void {
    const categoryId = categoryModel.uid
    const { eventManager } = this.appContext
    const translateFn = (text) => i18n.tc(text)

    new DialogFormBuilder('intents.category_edit', 'WidgetCategoryForm', {
      eventManager,
      i18n,
    })
      .setTranslateFunction(translateFn)
      .addToInitialModel({
        name: categoryModel.name,
        options: createValidOptions(categoryModel),
      })
      .addToFormOptions(createFormOptions('WidgetCategoryForm'))
      .setJsonSchema(
        createJsonSchema('res.data-view-categories.attr.name.label'),
      )
      .onSubmit((data: WidgetCategoryPayload) => {
        const model = { uid: categoryId, ...data }

        return this.widgetPersister.updateCategory(model)
      })
      .displayDialog()
  }
}
