<template>
  <v-card color="level0" class="full-height">
    <v-toolbar>
      <v-text-field
        v-model="search"
        clearable
        prepend-inner-icon="search"
        :loading="loading"
        solo
        hide-details
        dense
        :label="$t('layout.search_placeholder')"
      ></v-text-field>
    </v-toolbar>
    <v-list>
      <WidgetSearchExtensionItem
        v-for="item in entries"
        :key="item.uid"
        :item="item"
        @click="onSelect(item)"
      />
    </v-list>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import WidgetSearchExtensionItem from '@/apps/app.tracktik.insights.studio/extensions/Search/WidgetSearchExtensionItem.vue'
import { debounce } from 'lodash'
import WidgetSearchProvider, {
  SearchProviderItemInterface,
} from '@/apps/app.tracktik.insights.studio/extensions/Search/WidgetSearchProvider'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { SearchTerm } from '@/plugins/o11n'

export default Vue.extend({
  name: 'WidgetSearchExtensionDialog',
  components: {
    WidgetSearchExtensionItem,
  },
  created() {
    this.searchProvider = new WidgetSearchProvider(this.$appContext)
  },
  methods: {
    onSelect(item?: SearchProviderItemInterface) {
      if (item?.route) {
        this.$router?.push(item.route)
      }
      this.$analytics.track(
        SearchTerm.create({ label: item?.description ?? 'no item' }),
      )
      this.$nextTick(() => {
        this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG_CLOSE, null)
      })
    },
    searchDebounce: debounce(function (term) {
      // @ts-ignore
      this.entries = this.searchProvider.search(term)
      // @ts-ignore
      this.loading = false
    }, 500),
  },
  data() {
    return {
      search: null,
      loading: false as false | 'orange',
      entries: [],
      searchProvider: null as null | WidgetSearchProvider,
    }
  },
  watch: {
    search(term: string) {
      this.loading = term ? 'orange' : false
      this.searchDebounce(term)
    },
  },
})
</script>
