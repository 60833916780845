<template>
  <TLoadingWave v-if="loading" />

  <div v-else-if="error" class="fill-height d-flex justify-center align-center">
    <v-sheet class="text-center transparent">
      <v-icon
        size="200"
        color="grey lighten-3"
        v-text="`mdi-alert-circle-outline`"
      />

      <h1 style="font-weight: 100" class="pa-5" v-text="$t('common.error')" />
      <v-btn @click="fetchWidgetByUid" v-text="$t('common.reload')" />
    </v-sheet>
  </div>

  <div v-else class="fill-height">
    <slot v-bind="{ dataView }" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { WidgetStoreInterface } from '@/tt-widget-factory'
import DataViewProvider from '../lib/DataViewProvider'

export default Vue.extend({
  name: 'DataViewFetcher',
  props: {
    viewId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      error: false,
      provider: new DataViewProvider(this.$appContext),
      dataView: null as WidgetStoreInterface | null,
    }
  },
  watch: {
    viewId: {
      handler() {
        this.fetchWidgetByUid()
      },
      immediate: true,
    },
  },
  methods: {
    fetchWidgetByUid() {
      this.loading = true
      this.error = false
      return this.provider
        .fetchWidgetByUid(this.viewId)
        .then((widget) => {
          if (widget) {
            this.dataView = widget
            this.$emit('reset', widget)
          } else this.error = true
        })
        .catch((err) => {
          this.$crash.captureException(err)
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
})
</script>
