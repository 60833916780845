import i18n from '@/plugins/i18n'
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { WidgetReference } from '@/tt-widget-factory'
import { LayoutWindowEvent } from '@/tt-app-layout'

export interface WidgetCopyPayload {
  widget: WidgetReference
  category: string
}

/**
 * Dashboard Create intent
 */
export default class WidgetCopy extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_COPY
  }

  /**
   * Trigger a form event
   * @param payload
   */
  async run(payload: WidgetCopyPayload) {
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
      is: 'WidgetCopyModal',
      title: i18n.tc('custom_widget_provider.my_insights'),
      props: { payload },
    })
  }
}
