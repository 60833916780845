import isEmpty from 'lodash/isEmpty'
import { cloneData } from '@/helpers/cloneData'

export const updateDashboardUID = (dashboard, uid) => {
  const newDashboard = cloneData(dashboard)

  newDashboard.rows = newDashboard.rows?.map((row, rowIndex) => {
    row.columns = row?.columns?.map((column, colIndex) => {
      if (Array.isArray(column.widgetLookup)) {
        column.widgetLookup = column.widgetLookup.map(
          (widgetLookup, tabIndex) => {
            if (isEmpty(widgetLookup)) {
              return widgetLookup
            }
            widgetLookup.uid = `${uid}|${rowIndex}|${colIndex}|${tabIndex}`

            return widgetLookup
          },
        )
      } else {
        if (isEmpty(column.widgetLookup)) {
          return column
        }
        column.widgetLookup.uid = `${uid}|${rowIndex}|${colIndex}|0`

        return column
      }
      return column
    })
    return row
  })

  return newDashboard
}
