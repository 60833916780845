import {
  EmptyValueRule,
  FormOptions,
  JSONSchema7,
} from '@tracktik/tt-json-schema-form'

export const createJsonSchema = (title: string): JSONSchema7 => ({
  type: 'object',
  properties: {
    name: {
      title: title,
      type: 'string',
    },
    options: {
      $ref: '#/definitions/Options',
    },
  },
  definitions: {
    Options: {
      type: 'object',
      properties: {
        bannerImage: {
          title: 'common.image_url',
          type: 'string',
          pattern: '(^$)|(^(https)://)',
        },
        icon: {
          title: 'common.icon',
          type: 'string',
        },
        color: {
          title: 'common.color',
          type: 'string',
        },
      },
    },
  },
  required: ['name'],
})

export const createFormOptions = (
  formName,
  emptyValueRule = EmptyValueRule.KEEP,
): FormOptions => ({
  emptyValues: emptyValueRule,
  definitions: {
    Options: {
      properties: {
        color: {
          is: 'ColorSelector',
        },
        icon: {
          is: 'IconSelector',
        },
      },
    },
    [formName]: {
      view: {
        is: 'WidgetCategoryForm',
      },
    },
  },
})
