<template>
  <ProvideChatSession v-bind="settings" class="fill-height">
    <div class="chat-background">
      <div class="chat-canvas">
        <VerticalStackCard v-slot="{clientHeight}">
          <ChatMessageWindow :style="{height: clientHeight-140+'px'}"/>
          <ChatBotInput class="mt-9 mb-9" style="height: 100px;"/>
        </VerticalStackCard>
      </div>
    </div>
  </ProvideChatSession>
</template>

<script lang="ts">
import Vue from 'vue'
import ChatBotMessage from './ChatBotMessage.vue'
import ChatBotMessageUser from './ChatBotMessageUser.vue'
import ChatMessageWindow from './ChatMessageWindow.vue'
import DashboardWidgetRow from "@/tt-widget-components/widgets/Dashboard/DashboardWidgetRow.vue";
import ChatBotInput from './ChatBotInput.vue'
import {ChatAgent, Conversation, ConversationStatus} from "@/tt-app-extensions/chat-bot/ChatSession";
import ProvideChatSession from "@/tt-app-extensions/chat-bot/components/ProvideChatSession.vue";

export default Vue.extend({
  name: 'ChatBotPage',
  components: {
    DashboardWidgetRow,
    ChatBotMessage,
    ChatBotMessageUser,
    ChatBotInput,
    ChatMessageWindow,
    ProvideChatSession
  },
  data(): any {
    return {
      settings: {
        chatAgent: {
          id: 'ops-advisor',
          name: 'TQL Ops Advisor',
          welcome: 'Welcome! I am your AI data assistant. I can help you find insights in your data. Ask me anything!',
          nextQuestions: [
            'What were my scheduling KPIs for last month by region?',
            'How many incidents by category happened in the last month?',
            'How many employees are in each of  by region?',
            'How many hours are scheduled this week?',
            'What was the direct labor cost by client last week?'
          ],
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        } as ChatAgent,
        conversation: {
          messages: [],
          status: ConversationStatus.New,
        } as Conversation
      }
    }
  },
  methods: {
    scrollToBottom() {
      setTimeout(() => {
        const chatWindow = document.querySelector('.chat-window')
        chatWindow.scrollTop = chatWindow.scrollHeight
      }, 100)
    }
  },
})
</script>
<style scoped>
.chat-background {
  background: #ebf1f9;
  padding: 20px;
  height: 100%;
}

.theme--dark .chat-background {
  background: #000;
}

.chat-canvas {
  max-width: 1100px;
  height: 100%;
  margin-left: 20px;
}
</style>
