import Vue from 'vue'
import ChatBotExtension from './components/ChatBotExtension.vue'
import { ExtensionInterface, LayoutExtensionSlots } from '@/tt-app-modular'

Vue.component('ChatBotExtension', ChatBotExtension)
export const CHAT_BOT_EXTENSION = 'extension.chat-bot.tracktik.com'

export default {
  name: CHAT_BOT_EXTENSION,
  slots: [LayoutExtensionSlots.TOOLBAR_RIGHT],
  component: {
    is: 'ChatBotExtension',
  },
} as ExtensionInterface
