<template>
  <v-text-field
    @keyup.enter="onEnter"
    :append-outer-icon="appendIcon"
    @click:append-outer="appendClick"
    outlined
    flat
    v-model="question"
    clear-icon="mdi-close"
    clearable
    placeholder="Type your question here..."

  />
</template>
<script lang="ts">
import ChatVue from "@/tt-app-extensions/chat-bot/components/ChatVue";

export default ChatVue.extend({
  name: 'ChatBotInput',
  data() {
    return {
      question: null
    }
  },
  computed: {
    appendIcon() {
      return this.question ? 'mdi-arrow-right-drop-circle-outline' : (this.loading ? 'mdi-stop-circle-outline' : '')
    }
  },
  methods: {
    onEnter() {
      if (this.loading || !this.question) {
        return;
      }
      if (this.question) {
        this.session.ask(this.question)
        this.question = ''
      }
    },
    appendClick() {
      if (this.loading) {
        this.session.stop()
      } else {
        this.session.ask(this.question)
        this.question = ''
      }
    }
  }
})
</script>
