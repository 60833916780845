import { AggregationQuery } from '@/tt-widget-factory/definitions'
import {
  HeatMapWidgetModel,
  PieChartWidgetModel,
  SingleCounterWidgetModel, TQLQueryWidgetModel,
} from '@/tt-widget-components'
import TQLQueryWidget from "@/tt-widget-components/widgets/TQLQuery/TQLQueryWidget.vue";

export default class NQLAggregationAdapter {
  query: AggregationQuery

  title: string | undefined

  constructor(query: AggregationQuery) {
    this.query = query
    this.title = this.buildTitle()
  }

  buildTitle() {
    if (this.query.dimensions!.length === 0) {
      return `${this.query.resource} Count`
    }
    if (this.query.dimensions!.length === 1) {
      return `${this.query.resource} Count by ${
        this.query.dimensions![0]!.attribute
      }`
    }
  }

  /**
   * Get widget
   */
  getWidget():
    | PieChartWidgetModel
    | SingleCounterWidgetModel
    | HeatMapWidgetModel
    | undefined {
    switch (this.query.dimensions!.length) {
      case 0:
        return {
          is: 'SingleCounterWidget',
          title: this.buildTitle(),
          dataSet: {
            resource: this.query.resource,
            measure: this.query.measures[0],
          },
        } as SingleCounterWidgetModel
      case 1:
        return {
          is: 'PieChartWidget',
          title: this.buildTitle(),
          dataSet: {
            resource: this.query.resource,
            measure: this.query.measures[0],
            dimension: this.query.dimensions![0],
          },
        } as PieChartWidgetModel
      case 2:
        return {
          is: 'HeatMapWidget',
          title: this.buildTitle(),
          dataSet: {
            dimensions: this.query.dimensions,
            resource: this.query.resource,
            measure: this.query.measures[0],
          },
        } as HeatMapWidgetModel
      default:
        return
    }
  }

  public static fromTQL(tql: string, title: string): TQLQueryWidgetModel {
    return {
      is: 'TQLQueryWidget',
      title: title.toUpperCase(),
      dataSet: {
        tql: tql,
      }
    }
  }

  public static toWidget(query: AggregationQuery) {
    if (!query.resource) {
      return null
    }
    const adapter = new NQLAggregationAdapter(query)

    return adapter.getWidget()
  }
}
