<template>
  <div>
    <v-row v-if="categories.length" class="mb-2">
      <v-col
        v-for="(item, i) in categories"
        :key="`category-${i}`"
        :cols="colSize"
      >
        <CategoryCard :item="item" @click="goToCategory(item)" />
      </v-col>
    </v-row>

    <WidgetSearchResults
      :cols="colSize"
      :search-provider="searchProvider"
      view-mode="CARD"
      @click="gotToWidget($event)"
    />
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { WidgetCategoryModel, WidgetStoreModel } from '@/tt-widget-factory'
import { CategoryNames } from '@/apps/app.tracktik.insights.studio/types'
import { WidgetCollectionSearchProviderInterface } from '@/tt-widget-factory/services/widget-collections/types'
import { ChooseFolder, ChooseWidget } from '@/plugins/o11n'

export default Vue.extend({
  name: 'MyInsightsBanner',
  computed: {
    colSize(): number {
      return {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 2,
      }[this.$vuetify.breakpoint.name]
    },
    categories(): WidgetCategoryModel[] {
      return WidgetCategoryModel.query()
        .orderBy((item: WidgetCategoryModel) => item.meta.updatedOn, 'desc')
        .all()
        .filter((model: WidgetCategoryModel) => {
          return (
            model.parentCategory === CategoryNames.MY_INSIGHTS &&
            model.ownedByMe
          )
        })
    },
    searchProvider(): WidgetCollectionSearchProviderInterface {
      return {
        categoryId: CategoryNames.MY_INSIGHTS,
        limit: 8,
      }
    },
  },
  methods: {
    gotToWidget(widget: WidgetStoreModel) {
      this.$analytics.track(ChooseWidget.create({ label: widget.is }))
      this.$router.push(`/insight-studio/view/${widget.uid}`)
    },
    goToCategory(widgetCategory: WidgetCategoryModel) {
      this.$analytics.track(ChooseFolder.create({ label: widgetCategory.name }))
      this.$router.push(`/insight-studio/explore/${widgetCategory.uid}`)
    },
  },
})
</script>
