import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'

// Only exists to listen to as a callback
export default class CategoryUpdated {
  getEventName(): string {
    return WidgetEditorEvents.CATEGORY_UPDATED
  }

  run(payload: any): any {}
}
