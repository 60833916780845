<template>
  <div>
    <div class="pa-6">
      {{ message }}
    </div>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="grey" @click="close">
        {{ $t('common.cancel.btn') }}
      </v-btn>

      <v-btn text class="red--text" @click="confirm">
        {{ $t('common.delete.btn') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { DeleteFolder } from '@/plugins/o11n'

export default Vue.extend({
  name: 'CategoryDeleteFolderDialog',
  props: {
    message: {
      type: String,
      required: true,
    },
    onConfirm: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  methods: {
    close(): void {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.DIALOG_CLOSE,
      )
    },
    confirm(): void {
      this.$analytics.track(DeleteFolder.create())
      this.onConfirm()
      this.close()
    },
  },
})
</script>
