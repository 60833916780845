<template>
  <div>
    <div @contextmenu="show"><slot></slot></div>
    <v-menu
      v-model="showMenu"
      absolute
      :close-on-click="true"
      offset-y
      :position-x="x"
      :position-y="y"
    >
      <AppActionMenu :menu="menu" />
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ActionMenuConfig } from '../types'
export default Vue.extend({
  name: 'ActionContextualMenu',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Object as PropType<ActionMenuConfig>,
    },
  },
  data() {
    return {
      showMenu: false,
      x: 0,
      y: 0,
    }
  },
  methods: {
    show(e) {
      if (this.disabled) return
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
  },
})
</script>
