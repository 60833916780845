<template>
  <v-row no-gutters>
    <v-col v-for="item in collections" :key="item.uid" :cols="colSize">
      <div class="pa-2">
        <CategoryCard
          :item="item"
          :display-menu-items="false"
          @click="goTo(item.uid)"
        />
      </div>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import Vue from 'vue'
import { WidgetCategoryModel } from '@/tt-widget-factory'
import { CategoryNames } from '@/apps/app.tracktik.insights.studio/types'

export default Vue.extend({
  name: 'CollectionBanner',
  computed: {
    colSize(): number {
      return {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 2,
      }[this.$vuetify.breakpoint.name]
    },
    collections(): WidgetCategoryModel[] {
      return WidgetCategoryModel.query()
        .where('parentCategory', null)
        .all()
        .filter((widgetCategoryModel: WidgetCategoryModel) => {
          return ![
            CategoryNames.TUTORIALS,
            CategoryNames.MY_INSIGHTS,
            CategoryNames.STAFF,
            CategoryNames.CLIENT_PORTAL,
            '',
          ].includes(widgetCategoryModel.uid)
        })
    },
  },
  methods: {
    goTo(uid: string) {
      this.$router.push(`/insight-studio/explore/${uid}`)
    },
  },
})
</script>
