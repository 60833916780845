<template>
  <v-toolbar-items>
    <v-autocomplete
      v-if="!isMobile"
      ref="searchBar"
      v-model="model"
      dense
      flat
      :loading="loading"
      :background-color="backgroundColor"
      :items="entries"
      :filter="() => true"
      return-object
      hide-no-data
      hide-selected
      hide-details
      solo
      :placeholder="$t('layout.search_placeholder')"
      class="toolbar--search--input"
      clearable
      append-icon=""
      prepend-inner-icon="search"
      @change="onSelect"
      @update:search-input="searchDebounce"
      @focus="clean"
    >
      <template #selection>
        <span></span>
      </template>
      <template #item="data">
        <WidgetSearchExtensionItem v-if="data && data.item" :item="data.item" />
      </template>
      <template #prepend-inner>
        <v-icon small class="mr-2">search</v-icon>
      </template>
    </v-autocomplete>
    <template v-else>
      <v-btn small icon class="ml-2" style="opacity: 0.8" @click="openSearch">
        <v-icon>search</v-icon>
      </v-btn>
    </template>
  </v-toolbar-items>
</template>
<script lang="ts">
import Vue from 'vue'
import WidgetSearchExtensionItem from '@/apps/app.tracktik.insights.studio/extensions/Search/WidgetSearchExtensionItem.vue'
import WidgetSearchProvider, {
  SearchProviderItemInterface,
} from '@/apps/app.tracktik.insights.studio/extensions/Search/WidgetSearchProvider'
import { debounce } from 'lodash'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { SearchTerm } from '@/plugins/o11n'
import { WidgetEditorEvents } from '../../types'

export default Vue.extend({
  name: 'WidgetSearchExtensionInput',
  components: {
    WidgetSearchExtensionItem,
  },
  created() {
    this.searchProvider = new WidgetSearchProvider(this.$appContext)
  },
  methods: {
    clean() {
      this.model = null
      this.entries = []
    },
    openSearch() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
        is: 'WidgetSearchExtensionDialog',
        title: ' ',
        props: {},
      })
    },
    onSelect(item?: SearchProviderItemInterface) {
      if (!item) {
        return
      }
      const { label, archived, route, widget } = item
      this.$analytics.track(SearchTerm.create({ label }))
      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.SIDE_SHEET_CLOSE,
        () => ({}),
      )
      if (archived) {
        this.$eventManager.dispatchEvent(
          WidgetEditorEvents.WIDGET_UNARCHIVE,
          widget,
        )
      } else if (route) {
        this.$router.push(route)
      }
    },
    searchDebounce: debounce(function (this, term) {
      this.entries = this.searchProvider.search(term)
      this.loading = false
    }, 500),
  },
  data() {
    return {
      loading: false as boolean | 'orange',
      entries: [],
      model: null,
      searchProvider: null as null | WidgetSearchProvider,
    }
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs
    },
    backgroundColor(): string {
      return this.$vuetify.theme.dark ? 'black' : 'secondary darken-2'
    },
  },
})
</script>
<style>
.toolbar--search--input.v-text-field.v-text-field--solo.v-input--dense
  > .v-input__control {
  margin-left: 20px;
  margin-top: 9px;
  opacity: 0.7;
  font-size: 0.8em;
  border-radius: 5px;
  border-color: #dddddd;
  min-height: 28px;
}

.theme--dark
  .toolbar--search--input.v-text-field.v-text-field--solo.v-input--dense
  > .v-input__control {
  border: 1px solid #000;
}
</style>
