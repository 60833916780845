<template>
  <v-menu open-on-hover :nudge-width="350" :open-delay="300">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="pa-4" style="height: 340px">
      <widget-factory
        :widget="widget"
        :props-data="{ container: { height: 300 } }"
    /></v-card>
  </v-menu>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import { WidgetReference } from '@/tt-widget-factory/types'

export default Vue.extend({
  name: 'WidgetMouseOverPreview',
  props: {
    value: Boolean,
    controls: Boolean,
    widget: Object as PropType<WidgetReference>,
  },
})
</script>
