import {
  WidgetArchiveModel,
  WidgetCategoryModel,
  WidgetReference,
  WidgetStoreModel,
} from '@/tt-widget-factory/types'
import { AclRule, THIS } from '@/tt-widget-sharable'
import { UserGroupsModel } from '@/tt-widget-sharable'

import {
  WidgetCategoryInterface,
  WidgetCollectionSearchProviderInterface,
  WidgetProviderInterface,
  WidgetTagModel,
} from './types'

const enforceArray = (val: unknown | unknown[]): unknown[] =>
  Array.isArray(val) ? val : [val]

export const hasParentCategory = ({
  parentCategory,
}: {
  parentCategory: string
}) =>
  parentCategory !== null &&
  parentCategory !== undefined &&
  parentCategory !== ''

/**
 * Differenciate items shared with the user "explicitly" (AclRule source THIS)
 * and shared because of a parent folder (AclRule source PARENT)
 */
export const isExplicitlySharedWithMe = ({
  meta,
}: {
  meta?: { aclRule?: AclRule }
}) => {
  const grantedRules = meta?.aclRule?.grant || {}
  const currentUserTags: string[] = UserGroupsModel.getters('myUserGroups')
  const sourceIsThis = ([tag, rule]) => rule.source === THIS
  const currentUserHasTag = ([tag, rule]) => currentUserTags.includes(tag)

  return Object.entries(grantedRules)
    .filter(sourceIsThis)
    .some(currentUserHasTag)
}

const sortKey = (key: string) => (item: WidgetStoreModel) => item.meta[key]

/**
 * Widget collection manager
 */
export default class WidgetCollectionManager {
  views: WidgetReference[]
  categories: WidgetCategoryInterface[]

  /**
   * Register the provider
   */
  private providers: WidgetProviderInterface[] = []

  /**
   * Get the categories
   * @param searchProvider
   */
  public getCategories(
    searchProvider?: WidgetCollectionSearchProviderInterface,
  ): WidgetCategoryModel[] {
    return WidgetCategoryModel.query()
      .where((item: WidgetCategoryModel) => {
        if (!searchProvider) {
          return !hasParentCategory(item)
        }

        if (searchProvider.categoryId) {
          if (item.parentCategory !== searchProvider.categoryId) {
            return false
          }

          const excludeIds = searchProvider.categoryIdExcludes
            ? searchProvider.categoryIdExcludes
            : []

          if (excludeIds.includes(searchProvider.categoryId as string)) {
            return false
          }
        }

        if (searchProvider && searchProvider.searchQuery) {
          if (
            !item.name
              .toLowerCase()
              .includes(searchProvider.searchQuery.toLowerCase())
          ) {
            return false
          }
        }

        if (searchProvider.sharedWithMe)
          if (
            item.ownedByMe ||
            !item?.meta?.ownedBy?.id ||
            !isExplicitlySharedWithMe(item)
          )
            return false

        return true
      })
      .orderBy(sortKey('updatedOn'), 'desc')
      .all()
  }

  // To Remove FE-555
  public getInactiveViews(): WidgetArchiveModel[] {
    return WidgetArchiveModel.all()
  }
  // To Remove FE-555
  public getViews(
    searchProvider?: WidgetCollectionSearchProviderInterface,
    { offset = 0 } = {},
  ): WidgetStoreModel[] {
    if (!searchProvider) {
      searchProvider = {
        categoryId: null,
      }
    }

    // VUEX ORM
    return WidgetStoreModel.query()
      .where((item: WidgetStoreModel) => filterByProvider(item, searchProvider))
      .orderBy(sortKey('updatedOn'), 'desc')
      .offset(offset)
      .limit(searchProvider.limit ?? 20)
      .get()
  }

  // To Remove FE-555
  getViewsCount(searchProvider?: WidgetCollectionSearchProviderInterface) {
    return WidgetStoreModel.query()
      .where((item: WidgetStoreModel) => filterByProvider(item, searchProvider))
      .count()
  }

  getProviderByName(name: string): WidgetProviderInterface {
    return this.providers.find((provider: WidgetProviderInterface) => {
      return provider.getProviderName() == name
    })
  }

  /**
   *
   * @param provider
   */

  // To modify FE-555
  async registerProvider(provider: WidgetProviderInterface) {
    const categories = await provider.getAllCategories()
    const widgets = await provider.getAllViews()

    if (provider.getAllInactiveViews) {
      const inactiveWidgets = await provider.getAllInactiveViews()

      if (inactiveWidgets) {
        WidgetArchiveModel.insert({ data: inactiveWidgets })
      }
    }

    if (categories) {
      WidgetCategoryModel.insert({ data: categories })
    }
    if (widgets) {
      WidgetStoreModel.insert({
        data: widgets,
      })
    }

    this.providers.push(provider)
  }

  public deleteAll() {
    WidgetArchiveModel.deleteAll()
    WidgetStoreModel.deleteAll()
    WidgetCategoryModel.deleteAll()
    WidgetTagModel.deleteAll()
  }
}
// TO CHECK IF YOU ARE STILL USING FE-555
function filterByProvider(
  item: WidgetStoreModel,
  searchProvider: WidgetCollectionSearchProviderInterface,
) {
  // Type filter
  if (searchProvider.typeFilter) {
    const types = enforceArray(searchProvider.typeFilter)
    if (!types.includes(item.is)) {
      return false
    }
  }

  // Type exclude
  if (searchProvider.typeFilterExclude) {
    const typesToExclude = enforceArray(searchProvider.typeFilterExclude)
    if (typesToExclude.includes(item.is)) {
      return false
    }
  }

  if (searchProvider.searchQuery) {
    if (
      !item.title
        .toLowerCase()
        .includes(searchProvider.searchQuery.toLowerCase())
    ) {
      return false
    }
  }

  if (searchProvider.sharedWithMe)
    if (
      item.ownedByMe ||
      !item?.meta?.ownedBy?.id ||
      !isExplicitlySharedWithMe(item)
    )
      return false

  if (searchProvider.ownedByMe)
    if (!item.ownedByMe || !item?.meta?.ownedBy?.id) return false

  // Category
  // No category, show all
  if (!searchProvider.categoryId || !item.category) return true

  const categories = enforceArray(searchProvider.categoryId)

  return categories.includes(item.category)
}
