<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <json-field name="name" />
      </v-col>
    </v-row>
    <CollapsibleHeader
      :title="$t('widget_category_form.collapsible_header_title')"
    >
      <v-row>
        <v-col>
          <json-field name="options.icon" :color="color" />
        </v-col>
        <v-col>
          <json-field name="options.color" />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <json-field name="options.bannerImage" />
        </v-col>
      </v-row>
    </CollapsibleHeader>
  </div>
</template>
<script lang="ts">
import Vue, { VueConstructor } from 'vue'
import { FormHookProvider } from '@/tt-widget-components'

export default (Vue as VueConstructor<Vue & FormHookProvider>).extend({
  name: 'WidgetCategoryForm',
  inject: ['formHook'],
  computed: {
    color(): string {
      return this.formHook().getPathValue('options.color') || 'green lighten-4'
    },
  },
})
</script>
