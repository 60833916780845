import Vue from 'vue'

import CategoryCard from './CategoryCard.vue'
import DashboardCreateWindow from './DashboardCreateWindow.vue'
import DragTrashCan from './DragTrashCan.vue'
import WidgetArchiveDialog from './WidgetArchiveDialog.vue'
import WidgetUnarchiveDialog from './WidgetUnarchiveDialog.vue'
import NewCategoryIcon from './NewCategoryIcon.vue'
import SidebarTree from './SidebarTree.vue'
import SidebarTreeMyInsights from './SidebarTreeMyInsights.vue'
import WidgetCreateWindow from './WidgetCreateWindow.vue'
import WidgetMouseOverPreview from './WidgetMouseOverPreview.vue'
import WidgetSearchResults from './WidgetSearchResults.vue'
import WidgetCopyModal from './WidgetCopyModal.vue'
import withProps from '@/helpers/withProps'
import WidgetPersister from '@/apps/app.tracktik.insights.studio/lib/WidgetPersister'
import WidgetCollectionManager from '@/tt-widget-factory/services/widget-collections/WidgetCollectionManager'
import StandaloneWidgetView from '@/apps/app.tracktik.insights.studio/views/StandaloneWidgetView.vue'
import HomeScreenView from '@/apps/app.tracktik.insights.studio/views/HomeScreenView.vue'
import SkeletonCategories from './skeletons/SkeletonCategories.vue'
import SkeletonWidgets from './skeletons/SkeletonWidgets.vue'
import WidgetCategoryForm from '@/apps/app.tracktik.insights.studio/components/WidgetCategoryForm.vue'
import EntityShareForm from '@/apps/app.tracktik.insights.studio/components/EntityShareForm.vue'
import IssueCollector from '@/tt-app-extensions/issue-collector/components/IssueCollector.vue'
import CategoryDeleteFolderDialog from './CategoryDeleteFolderDialog.vue'

const components = {
  CategoryCard,
  DragTrashCan,
  WidgetArchiveDialog,
  WidgetUnarchiveDialog,
  SidebarTree,
  SidebarTreeMyInsights,
  WidgetMouseOverPreview,
  EntityShareForm,
  IssueCollector,
  CategoryDeleteFolderDialog,
}

/**
 * Register the components with the module prop
 * @param module
 */
export function installInsightStudioComponents(
  widgetPersister: WidgetPersister,
  widgetCollectionManager: WidgetCollectionManager,
) {
  Vue.component('WidgetCopyModal', WidgetCopyModal)
  Vue.component('WidgetCategoryForm', WidgetCategoryForm)
  Vue.component('SkeletonCategories', SkeletonCategories)
  Vue.component('SkeletonWidgets', SkeletonWidgets)
  // These components need the widget persister
  Vue.component(
    'DashboardCreateWindow',
    withProps(DashboardCreateWindow, { widgetPersister }),
  )
  Vue.component(
    'WidgetCreateWindow',
    withProps(WidgetCreateWindow, { widgetPersister }),
  )
  Vue.component(
    'NewCategoryIcon',
    withProps(NewCategoryIcon, { widgetPersister }),
  )
  Vue.component(
    'WidgetSearchResults',
    withProps(WidgetSearchResults, { widgetCollectionManager }),
  )
  Vue.component('StandaloneWidgetView', StandaloneWidgetView)
  Vue.component('HomeScreenView', HomeScreenView)
  Object.keys(components).forEach((name: string) => {
    Vue.component(name, components[name])
  })
}
