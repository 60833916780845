<template>
  <div
    class="level1"
    style="display: inline-block; cursor: pointer; opacity: 0.8"
  >
    <div @click="onClick">
      <v-card
        v-for="(item, i) in items"
        :key="i"
        flat
        color="transparent"
        style="float: left"
        width="280"
        class="text-center pa-8"
      >
        <div style="text-align: center" :style="imageStyle">
          <v-img max-width="150" :src="item.img" class="elevation-2" />
        </div>
        <div class="text-left pa-2">
          <div class="font-weight-light" v-text="item.step" />
          <div
            class="font-weight-bold"
            v-text="$t(`create_dashboard.step${i + 1}.title`)"
          />
          <div
            class="caption"
            v-text="$t(`create_dashboard.step${i + 1}.description`)"
          />
        </div>
      </v-card>
      <v-card
        flat
        color="transparent"
        style="float: left"
        width="280"
        class="text-center pa-8"
      >
        <div :style="imageStyle">
          <v-img
            max-width="150"
            class="elevation-2"
            :src="`${imageBaseUrl}create-dashboard-step-4.svg`"
          />
        </div>
        <div class="text-left mt-6">
          <v-btn outlined small>
            <v-icon color="blue">add</v-icon>
            <span v-text="$t(`common.create.btn`)" />
          </v-btn>
        </div>
      </v-card>
      <div style="clear: both" />
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'

const imageBaseUrl = 'https://tt-insights-assets.s3.amazonaws.com/site-img/'
export default Vue.extend({
  name: 'CreateDashboardBanner',
  data() {
    return {
      items: [
        {
          step: 'STEP 1',
          img: `${imageBaseUrl}create-dashboard-step-1.svg`,
        },
        {
          step: 'STEP 2',
          img: `${imageBaseUrl}create-dashboard-step-2.svg`,
        },
        {
          step: 'STEP 3',
          img: `${imageBaseUrl}create-dashboard-step-3.svg`,
        },
      ],
    }
  },
  computed: {
    imageStyle(): { opacity: number } {
      return {
        opacity: this.$vuetify.theme.dark ? 0.5 : 0.8,
      }
    },
    imageBaseUrl() {
      return imageBaseUrl
    },
  },
  methods: {
    onClick() {
      this.$eventManager.dispatchEvent(WidgetEditorEvents.DASHBOARD_CREATE, {})
    },
  },
})
</script>
