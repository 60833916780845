<template>
  <v-btn-toggle v-model="model" flat x-small mandatory>
    <!-- btn: list view -->
    <v-tooltip top :open-delay="tooltipOpenDelay">
      <template #activator="{ on }">
        <v-btn x-small text value="LIST" v-on="on">
          <v-icon x-small>mdi-format-list-bulleted</v-icon>
        </v-btn>
      </template>
      <span v-text="$t('common.list-view')" />
    </v-tooltip>
    <!-- / btn: list view -->

    <!-- btn: grid view -->
    <v-tooltip top :open-delay="tooltipOpenDelay">
      <template #activator="{ on }">
        <v-btn x-small text value="GRID" v-on="on">
          <v-icon x-small>mdi-table</v-icon>
        </v-btn>
      </template>
      <span v-text="$t('common.grid-view')" />
    </v-tooltip>
    <!-- / btn: grid view -->
  </v-btn-toggle>
</template>

<script lang="ts">
import Vue from 'vue'
import { TOOLTIP_OPEN_DELAY } from '@/helpers/constants'

export default Vue.extend({
  name: 'WidgetViewModeSwitcher',
  props: {
    value: {
      type: String,
      default: null,
      required: true,
    },
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(value: string) {
        this.$emit('input', value)
      },
    },
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
  },
})
</script>

<style scoped></style>
