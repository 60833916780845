import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { LayoutWindowEvent, PageInterface } from '@/tt-app-layout'
import {
  WidgetEditInterface,
  WidgetEditorEvents,
} from '@/apps/app.tracktik.insights.studio/types'
import i18n from '@/plugins/i18n'
import { WidgetEditorType } from '@/tt-widget-factory'

export class WidgetEdit extends WidgetBaseIntent {
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_EDIT
  }
  run(payload: WidgetEditInterface): void {
    const widgetTypeConfig =
      this.appContext.widgetServices.widgetManager.getWidgetByName(
        payload.widgetType,
      )

    const isInlineEditorType =
      widgetTypeConfig?.config?.editor === WidgetEditorType.INLINE

    // ie: for a dashboard, we do not open the edit panel
    if (isInlineEditorType) return

    const title = payload?.widgetType
    const page: PageInterface = {
      is: 'WidgetEditView',
      title: title
        ? i18n.tc(`widget_edit.${title}.label`)
        : 'widget_edit.editor_title.label',
      closeButton: true,
      props: { ...payload },
    }
    this.appContext.eventManager.dispatchEvent(
      LayoutWindowEvent.SIDE_SHEET,
      page,
    )
  }
}
