<template>
  <v-card v-if="isAuthorized">
    <v-toolbar dense flat color="#fafafa">
      <v-app-bar-nav-icon>
        <v-icon color="orange">insert_chart</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="ml-3">Kitchen Sink Form</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row class="px-3">
        <v-col>
          <div class="pa-6">
            <JsonForm
              v-model="model"
              :schema="schema"
              :form-options="formOptions"
              :show-status="true"
            />
          </div>
        </v-col>
        <v-col>
          <WidgetJsonViewer :json="model" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
  <v-container v-else>You don't have permission.</v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import {
  DefinitionOption,
  EmptyValueRule,
  FormOptions,
  JsonForm,
} from '@tracktik/tt-json-schema-form'
import WidgetJsonViewer from '@/tt-widget-components/components/WidgetJsonViewer.vue'
import { createKitchenSinkJsonSchema } from './utils'
import { createEntityViewDefinitions } from '../EntityViewDefinitions'

export default Vue.extend({
  name: 'FormKitchenSink',
  components: { JsonForm, WidgetJsonViewer },
  data() {
    const apiFormOptionsDefinitions = createEntityViewDefinitions(
      this.$appContext.authModule.getUserPreferences(),
    )
    const serverUrl = new URL(this.$appContext.authModule.getServerUrl()).origin

    const __ROOT__: DefinitionOption = {
      properties: {
        Signature: {
          props: {
            background: `${serverUrl}/images/reports/signature_inspection.png`,
          },
        },
        DrawCanvas: {
          props: {
            background: `${serverUrl}/images/reports/map_body_injury_inspection.png`,
          },
        },
      },
    }

    const formOptions: FormOptions = {
      definitions: { ...apiFormOptionsDefinitions, __ROOT__ },
      emptyValues: EmptyValueRule.KEEP,
    }
    return {
      schema: createKitchenSinkJsonSchema(),
      formOptions,
      model: { Relation: 105 },
    }
  },
  computed: {
    isAuthorized() {
      return this.$appContext.authModule.hasPermission('superuser:*')
    },
  },
})
</script>
