<template>
  <div class="mb-12">
    <ExploreSection title="home_screen_view.predefined_dashboards" class="mb-6">
      <CollectionBanner />
    </ExploreSection>

    <ExploreSection
      v-if="metadatasReady && !showCreate"
      title="custom_widget_provider.my_insights"
      class="mb-6"
    >
      <MyInsightsBanner />
    </ExploreSection>

    <ExploreSection
      title="home_screen_view.create_your_first_dashboard"
      class="mb-6"
    >
      <CreateDashboardBanner />
    </ExploreSection>
    <ExploreSection v-if="hasWidgetsInTutorials" title="common.tutorials">
      <TutorialBanner />
    </ExploreSection>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
import TutorialBanner from '../components/TutorialBanner.vue'
import CreateDashboardBanner from '../components/CreateDashboardBanner.vue'
import CollectionBanner from '../components/CollectionBanner.vue'
import ExploreSection from '../components/ExploreSection.vue'
import MyInsightsBanner from '../components/MyInsightsBanner.vue'
import { WidgetStoreModel } from '@/tt-widget-factory'

export default Vue.extend({
  name: 'HomeScreenView',
  components: {
    TutorialBanner,
    CreateDashboardBanner,
    ExploreSection,
    CollectionBanner,
    MyInsightsBanner,
  },
  computed: {
    metadatasReady(): boolean {
      return this.$appContext.isReady()
    },
    hasWidgetsInTutorials(): boolean {
      return !!WidgetStoreModel.query()
        .all()
        .find((model: WidgetStoreModel) => {
          return model.provider === 'tutorials'
        })
    },
    hasWidgetsInMyInsights(): boolean {
      return !!WidgetStoreModel.query()
        .all()
        .find((model: WidgetStoreModel) => {
          return model.provider === 'my-insights'
        })
    },
    showCreate(): boolean {
      return (
        this.tabletAndUp && this.metadatasReady && !this.hasWidgetsInMyInsights
      )
    },
    tabletAndUp(): boolean {
      return this.$vuetify.breakpoint.smAndUp
    },
  },
})
</script>
