import { Analytics } from '@tracktik/tt-observability/dist/src/analytics'

import i18n from '@/plugins/i18n'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { Resources } from '@/tt-entity-design/src/types'
import { ShareFolder } from '@/plugins/o11n'
import { WidgetCategoryModel } from '@/tt-widget-factory'

import WidgetBaseIntent from './WidgetBaseIntent'
import WidgetPersister from '../lib/WidgetPersister'
import { OnSubmitFn } from './WidgetShare'
import { WidgetEditorEvents } from '../types'

export default class CategoryShare extends WidgetBaseIntent {
  getEventName(): string {
    return WidgetEditorEvents.CATEGORY_SHARE
  }

  run(category: WidgetCategoryModel): void {
    const persister = new WidgetPersister(this.appContext) // @todo: we shoud have a CategoryPersister
    const fetchMetadata = () =>
      persister.getCategoryByUid(category.uid).then((res) => res.meta)

    const trackShareFolder = (analytics: Analytics) => () =>
      analytics.track(ShareFolder.create({ label: category.name }))

    const updateAclRule: OnSubmitFn = (aclRule, analytics) =>
      persister
        .shareCategory(+category.meta.id, aclRule)
        .finally(trackShareFolder(analytics))

    const updateStoreCategory = () =>
      persister.reloadCategoryByID(String(category.meta.id))

    updateStoreCategory()

    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
      is: 'EntityShareForm',
      title: i18n.t('intents.widget_share.dialog_title'),
      width: 700,
      props: {
        fetchMetadata,
        resourceName: Resources.DATA_VIEW_CATEGORIES,
        onSubmit: updateAclRule,
      },
    })
  }
}
