import ResourcePermissionAuditor, {
  getResourcePermissionAuditorServices,
} from '@/tt-widget-factory/services/resource-meta/ResourcePermissionAuditor'
import { AppContext } from '@/tt-app-context'
import { CollectionQueryResponse } from '@/tt-widget-factory/definitions'
import { Resources } from '@/tt-entity-design/src/types'
import {
  WidgetCategoryInterface,
  WidgetProviderInterface,
} from '@/tt-widget-factory/services/widget-collections/types'
import { WidgetReference, WidgetStoreInterface } from '@/tt-widget-factory'

import {
  categoryQuery,
  getInactiveQuery,
  widgetQuery,
} from './CustomWidgetProviderQuery'
import {
  getCategoryName,
  toCategoryModel,
  toWidgetArchiveModel,
  toWidgetStoreModel,
} from './DataViewProvider/normalize-data-view-response'
import { CUSTOM_WIDGET_PROVIDER_NAME } from './DataViewProvider'

export interface DataViewResource extends WidgetReference {
  id?: any
  subTitle: string
  configs: string
}

/**
 * Custom Widget Collection Provider
 */
export default class CustomWidgetProvider implements WidgetProviderInterface {
  appContext: AppContext
  userId: number

  /**
   * Data manager
   * @param dataManager
   */
  constructor(appContext: AppContext) {
    this.appContext = appContext
    this.userId = appContext.authModule.getUserId()
  }

  /**
   *  Fetch all views
   */
  // To Delete FE-555
  async getAllViews(): Promise<WidgetStoreInterface[]> {
    // No permission to view
    if (
      !ResourcePermissionAuditor.canViewResource(
        getResourcePermissionAuditorServices(this.appContext),
        Resources.DATA_VIEWS,
      )
    ) {
      return []
    }

    const myId = this.appContext.authModule.getUserId()
    return this.appContext.widgetServices.resourceDataManager
      .getCollection(widgetQuery)
      .then((response: CollectionQueryResponse) => {
        return response.items.map((dataView: any) => {
          return toWidgetStoreModel(dataView, { myId })
        })
      })
  }

  /**
   *  Fetch all inactive views
   */
  // To Delete FE-555
  async getAllInactiveViews(): Promise<WidgetStoreInterface[]> {
    // No permission to view
    if (
      !ResourcePermissionAuditor.canViewResource(
        getResourcePermissionAuditorServices(this.appContext),
        Resources.DATA_VIEWS,
      )
    ) {
      return []
    }

    const myId = this.appContext.authModule.getUserId()
    const inactiveQuery = getInactiveQuery(myId)
    return this.appContext.widgetServices.resourceDataManager
      .getCollection(inactiveQuery)
      .then((response: CollectionQueryResponse) => {
        return response.items.map((dataView: any) => {
          return toWidgetArchiveModel(dataView, { myId })
        })
      })
  }

  /**
   * Get all categories
   */
  async getAllCategories(): Promise<WidgetCategoryInterface[]> {
    const myId = this.appContext.authModule.getUserId()

    // No permission to view
    if (
      !ResourcePermissionAuditor.canViewResource(
        getResourcePermissionAuditorServices(this.appContext),
        Resources.DATA_VIEW_CATEGORIES,
      )
    ) {
      return []
    }

    return this.appContext.widgetServices.resourceDataManager
      .getCollection(categoryQuery)
      .then((response: CollectionQueryResponse) => {
        const items = response.items.map((category: any) => {
          return toCategoryModel(category, {
            myId,
          })
        })

        items.push({
          provider: CUSTOM_WIDGET_PROVIDER_NAME,
          uid: getCategoryName(null),
          name: 'custom_widget_provider.my_insights',
          meta: {
            icon: 'mdi-folder-plus',
          },
          ownedByMe: false,
          description: 'Custom widgets',
        })

        return items
      })
  }

  /**
   * Provider lable
   */
  getProviderLabel(): string {
    return 'My Insights'
  }

  getProviderName(): string {
    return CUSTOM_WIDGET_PROVIDER_NAME
  }
}
