import { LayoutWindowEvent } from '@/tt-app-layout'
import {
  WidgetEditorEvents,
  WidgetExploreEvents,
} from '@/apps/app.tracktik.insights.studio/types'
import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import i18n from '@/plugins/i18n'
import { EventManagerInterface } from '@/tt-event-manager'
import WidgetPersister from '../lib/WidgetPersister'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import { WidgetCategoryModel } from '@/tt-widget-factory'
import { createFormOptions, createJsonSchema } from './CategoryJsonSchema'
import { WidgetCategoryPayload } from '@/tt-widget-factory/WidgetCategoryModel'
import { EmptyValueRule } from '@tracktik/tt-json-schema-form'
import { AppContextEventPayload } from '@/tt-app-context'

const createSubmitFn =
  (
    eventManager: EventManagerInterface<AppContextEventPayload>,
    widgetPersister: WidgetPersister,
    currentCategory: { uid: string },
  ) =>
  (formModel: WidgetCategoryPayload) =>
    widgetPersister
      .createCategory({
        name: formModel.name,
        parentCategory: currentCategory.uid,
        options: formModel.options,
      })
      .then(() => {
        eventManager.dispatchEvent(WidgetExploreEvents.ROUTE_TO_EXPLORE, {
          routeParams: [currentCategory.uid],
        })

        eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
          message: i18n.t(
            'intents.category_create_sub.subcategory_created_successfully',
          ),
        })
      })
      .catch((e) => {
        console.error(e)
      })

/**
 * Dashboard Create intent
 */
export default class CategoryCreateSub extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.CATEGORY_CREATE_SUB
  }

  /**
   * Trigger a form event
   * @param currentCategory
   */

  async run(currentCategory: WidgetCategoryModel): Promise<void> {
    const title = i18n.tc('intents.category_create_sub.create_new_category')
    const { eventManager } = this.appContext
    const translateFn = (text) => i18n.tc(text)
    const submitFn = createSubmitFn(
      eventManager,
      this.widgetPersister,
      currentCategory,
    )

    new DialogFormBuilder(title, 'CreateSubcategoryForm', {
      eventManager,
      i18n,
    })
      .setTranslateFunction(translateFn)
      .addText(
        'name',
        i18n.tc('intents.category_create_sub.new_category_name'),
        {
          required: true,
          defaultValue: i18n.tc(
            'intents.category_create_sub.new_category_name',
          ),
        },
      )
      .addToFormOptions(
        createFormOptions('CreateSubcategoryForm', EmptyValueRule.REMOVE),
      )
      .setJsonSchema(
        createJsonSchema('intents.category_create_sub.new_category_name'),
      )
      .onSubmit(submitFn)
      .displayDialog()
  }
}
