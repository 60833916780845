<template>
  <div>
    <ChatBotMessage :avatar="avatar" :message="message" :color="color" :name="name" :stream="true"/>
    <AppWindow v-if="message.data" ><ChatBotDataView :message="message"/></AppWindow>
  </div>
</template>
<script lang="ts">
import Vue, {PropType} from 'vue'
import {AssistantChatMessage} from "@/tt-app-extensions/chat-bot/types";
import ChatBotMessage from "@/tt-app-extensions/chat-bot/components/ChatBotMessage.vue";
import ChatBotDataView from "@/tt-app-extensions/chat-bot/components/ChatBotDataView.vue";

export default Vue.extend({
  name: 'ChatBotMessageAssistant',
  components: {
    ChatBotDataView,
    ChatBotMessage
  },
  data() {
    return {
      color: 'white',
      name: 'AI Assistant',
      avatar: 'https://pics.craiyon.com/2023-09-13/e538438db47f4343918f2fcc9d18a0a3.webp',
    }
  },
  props: {
    message: {
      type: Object as PropType<AssistantChatMessage>,
      required: true,
    }
  }
})
</script>
