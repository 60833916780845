<template>
  <v-tooltip :disabled="showTooltip" left nudge-bottom="15" nudge-right="50">
    <template #activator="{ on: tooltip }">
      <v-menu bottom open-on-hover :open-on-click="false">
        <template #activator="{ on: menu }">
          <div
            class="text-truncate"
            :class="deprecatedEndpointClass(item)"
            @mouseover="hoverItem"
            v-on="{ ...menu, ...tooltip }"
            v-text="$t(item.name)"
          />
        </template>
        <v-card v-if="item.type == 'attribute'" class="pb-0 level1">
          <v-list three-line dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon :color="item.color" v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ item.name }}
                  <span v-if="item.item.deprecated">
                    {{ `(${$t('data_doc.deprecated')})` }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-title v-text="$t(item.label)" />
                <v-list-item-subtitle>
                  {{ $t('data_doc.type') }}: {{ item.subType }}
                  <template v-if="item.options">
                    <span>
                      {{ $t('data_doc.options') }}:&nbsp;
                      {{ item.options.join(', ') }}
                    </span>
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
    <span>{{ itemNames(item) }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { DataDocTreeItem } from '../lib/data-doc'

export default Vue.extend({
  name: 'DataDocItem',
  props: {
    item: {
      type: Object as PropType<DataDocTreeItem>,
      required: true,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    showTooltip(): boolean {
      return this.item.type == 'attribute' || !this.hover
    },
  },
  methods: {
    itemNames(item) {
      if (item.name === 'data_doc.data_models')
        return this.$t('data_doc.data_models')
      if (item.name === 'data_doc.functions')
        return this.$t('data_doc.functions')
      if (item.name === 'data_doc.relative_dates')
        return this.$t('data_doc.relative_dates')
      return item.name
    },
    deprecatedEndpointClass(item): string {
      return item.item?.deprecated
        ? 'text-decoration-line-through'
        : 'text-decoration-none'
    },
    hoverItem(e) {
      // Verifies if the title width is bigger than the available space
      // therefore ellipsis applies and only then tooltip is shown
      this.hover = e.target.offsetWidth < e.target.scrollWidth
    },
  },
})
</script>
