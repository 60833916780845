<template>
  <div>
    <div class="px-6 py-3">
      <p>
        <WidgetIcon class="mr-1" :widget-type="widget.is" />
        {{ widget.title }}
      </p>
      <p v-text="$t(message)"></p>
    </div>

    <v-card-text class="archive-permissions">
      <v-divider />
      <v-checkbox
        v-model="restorePermissions"
        :label="$t('common.restore')"
        data-cy="check-restore-permissions"
      ></v-checkbox>
      <v-tooltip bottom nudge-right="180">
        <template #activator="{ on, attrs }">
          <v-icon class="icon-info" v-bind="attrs" v-on="on">
            mdi-information-outline
          </v-icon>
        </template>
        <span
          class="archive-tooltip-text"
          v-text="$t('common.restore_info')"
        ></span>
      </v-tooltip>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="grey" @click="close">
        <span v-text="$t('common.cancel.btn')"></span>
      </v-btn>

      <v-btn
        class="ma-2"
        color="success"
        data-cy="btn-archive-restore"
        depressed
        @click="confirm()"
      >
        <span v-text="$t('common.restore.btn')"></span>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ConfirmUnarchveFn } from '../intents/WidgetUnarchive'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetReference } from '@/tt-widget-factory'
import { Unarchive } from '@/plugins/o11n'

export default Vue.extend({
  name: 'WidgetUnarchiveDialog',
  props: {
    message: {
      type: String,
      required: true,
    },
    onConfirm: {
      type: Function as PropType<ConfirmUnarchveFn>,
      required: true,
    },
    widget: {
      type: Object as PropType<WidgetReference>,
      required: true,
    },
  },
  data() {
    return {
      restorePermissions: false,
    }
  },
  methods: {
    close(): void {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.DIALOG_CLOSE,
      )
    },
    confirm(): void {
      this.onConfirm(this.restorePermissions)
      this.close()
      this.$analytics.track(Unarchive.create())
    },
  },
})
</script>

<style scoped>
.archive-permissions {
  padding: 0 24px 20px;
}

.archive-permissions >>> .v-icon.icon-info {
  font-size: 16px;
  left: 8px;
  position: relative;
  top: -5px;
}

.archive-permissions >>> .v-input {
  display: inline-block;
}

.archive-permissions >>> .v-label {
  font-size: 13px;
}

.archive-permissions >>> .v-messages {
  display: none;
}

.archive-tooltip-text {
  font-size: 13px;
}
</style>
