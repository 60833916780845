<template>
  <v-card
    flat
    color="transparent"
    style="opacity: 1; font-weight: 300; font-size: 0.8em"
  >
    <v-treeview
      :items="items"
      hoverable
      transition
      :open-on-click="true"
      :return-object="true"
      class="sidebar-tree-view tree-root-level"
      @update:active="changeRoute"
    >
      <template #label="{ item }">
        <drop :draggable="item.item" @drop="moveTo($event, item.item)">
          <router-link
            v-if="item.to"
            :to="item.to"
            class="white--text"
            style="text-decoration: none"
          >
            <v-tooltip
              top
              left
              :disabled="!showTooltip"
              :open-delay="tooltipOpenDelay"
            >
              <template #activator="{ on }">
                <div
                  class="d-flex flex-grow-1 pl-2 pt-2 pb-2"
                  :class="isRouteActive(item) ? 'highlighted' : ''"
                  v-on="on"
                >
                  <v-icon
                    small
                    style="opacity: 0.7"
                    :color="item.color"
                    class="mr-3"
                    v-text="item.icon"
                  />
                  <label class="cursorPointer">
                    <span v-text="$t(item.name)" />
                  </label>
                </div>
              </template>
              <span v-text="$t(item.name)" />
            </v-tooltip>
          </router-link>
        </drop>
      </template>
    </v-treeview>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import {
  DraggableInterface,
  SideBarTreeNode,
  WidgetEditorEvents,
} from '../types'
import { omitPushErrors } from '@/plugins/router'
import { TOOLTIP_OPEN_DELAY } from '@/helpers/constants'

export default Vue.extend({
  name: 'SidebarTree',
  props: {
    navigateToFolderView: Function,
    items: Array,
    showTooltip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tooltipOpenDelay(): number {
      return TOOLTIP_OPEN_DELAY
    },
  },
  methods: {
    isRouteActive(selectedItem: SideBarTreeNode): boolean {
      const destination = selectedItem?.to
      const current = this.$router.currentRoute.fullPath

      return !!(destination === current)
    },
    changeRoute([selectedItem]) {
      const to = selectedItem && selectedItem.to
      const current = this.$router.currentRoute.fullPath
      if (to && to !== current) {
        this.$router.push(to).catch(omitPushErrors(['NavigationDuplicated']))
      }
    },
    moveTo(droppable: DraggableInterface, category) {
      this.$eventManager.dispatchEvent(WidgetEditorEvents.DROP_ON_CATEGORY, {
        ...droppable,
        target: category,
      })
    },
  },
})
</script>

<style scoped lang="scss">
.highlighted {
  background-color: rgba(255, 255, 255, 0.24);
}

.sidebar-tree-view {
  ::v-deep .v-treeview-node__root {
    min-height: 40px;
  }
}
</style>
