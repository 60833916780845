<template>
  <v-card v-if="isAuthorized">
    <v-toolbar dense flat color="#fafafa">
      <v-app-bar-nav-icon>
        <v-icon color="orange">insert_chart</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="ml-3">
        OpenApi JsonSchemaForm Demo
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col>
          <v-autocomplete
            v-model="selectedKey"
            dense
            outlined
            :items="definitionsKeys"
            @input="model = {}"
          />
        </v-col>
      </v-row>
      <v-row v-if="schema" class="px-3">
        <v-col>
          <div class="pa-6">
            <JsonForm
              v-model="model"
              :schema="schema"
              :form-options="formOptions"
              :show-status="true"
            />
          </div>
        </v-col>
        <v-col>
          <WidgetJsonViewer :json="model" />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
  <v-container v-else>You don't have permission.</v-container>
</template>

<script lang="ts">
import Vue from 'vue'

import {
  EmptyValueRule,
  FormOptions,
  JsonForm,
} from '@tracktik/tt-json-schema-form'

import metadataProvider, {
  MetaKey,
} from '@/tt-widget-factory/services/metadata-provider'
import WidgetJsonViewer from '@/tt-widget-components/components/WidgetJsonViewer.vue'

import { getResourceDefinitions } from '../EntityViewDefinitions'

export default Vue.extend({
  name: 'OpenApiFormDemo',
  components: { JsonForm, WidgetJsonViewer },
  data() {
    const definitions = getResourceDefinitions(this.$appContext)
    const formOptions: FormOptions = {
      definitions,
      emptyValues: EmptyValueRule.KEEP,
    }
    return {
      formOptions,
      model: {},
      selectedKey: '',
    }
  },
  computed: {
    openApiSchema(): any {
      return metadataProvider.get(MetaKey.openApiSchema)
    },
    isAuthorized(): boolean {
      return this.$appContext.authModule.hasPermission('superuser:*')
    },
    definitionsKeys(): string[] {
      return Object.keys(this.openApiSchema.components.schemas)
    },
    schema(): any {
      if (!this.selectedKey) return null
      const selectedSchema =
        this.openApiSchema.components.schemas[this.selectedKey]
      return {
        components: this.openApiSchema.components,
        type: 'object',
        properties: { selectedSchema },
      }
    },
  },
})
</script>
