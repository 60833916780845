import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
/**
 * Dashboard Create intent
 */
export default class WidgetLog extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_LOG
  }

  /**
   * Trigger a form event
   * @param payload
   */
  run(payload: any) {}
}
