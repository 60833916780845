<template>
  <v-row class="ma-4" no-gutters>
    <v-col
      v-for="(layout, index) in layouts"
      :key="`layout_${index}`"
      :cols="cols"
      class="mb-6"
    >
      <button
        class="mb-2"
        style="cursor: pointer"
        @click="$emit('input', layout.data)"
      >
        <DashboardLayoutThumbnail
          :data="layout.data"
          :height="100"
          :margin="5"
          :border="true"
        >
          <div
            style="
              opacity: 0.4;
              background: cadetblue;
              height: 100%;
              width: 100%;
            "
          ></div>
        </DashboardLayoutThumbnail>
      </button>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import Vue from 'vue'

import { DashboardWidgetRowSizes } from '@/tt-widget-components/widgets/Dashboard/types'
import DashboardLayoutThumbnail from './DashboardLayoutThumbnail.vue'
import { DashboardColumn, DashboardRow } from '@/tt-widget-components'

function col(): DashboardColumn {
  return {
    widgetLookup: {},
  } as DashboardColumn
}

export default Vue.extend({
  components: {
    DashboardLayoutThumbnail,
  },
  props: {
    value: Array,
  },
  data() {
    return {
      model: {},
      layouts: [
        {
          data: [
            {
              size: DashboardWidgetRowSizes.EXTRA_SMALL,
              columns: [col(), col(), col()],
            },
            {
              size: DashboardWidgetRowSizes.EXTRA_LARGE,
              columns: [col(), col()],
            },
          ] as DashboardRow[],
        },
        {
          data: [
            { size: DashboardWidgetRowSizes.MEDIUM, columns: [col(), col()] },
            { size: DashboardWidgetRowSizes.MEDIUM, columns: [col(), col()] },
          ] as DashboardRow[],
        },
        {
          data: [
            { size: DashboardWidgetRowSizes.MEDIUM, columns: [col(), col()] },
            { size: DashboardWidgetRowSizes.MEDIUM, columns: [col()] },
          ] as DashboardRow[],
        },
        {
          data: [
            { size: DashboardWidgetRowSizes.SMALL, columns: [col(), col()] },
            {
              size: DashboardWidgetRowSizes.MEDIUM,
              columns: [col(), col(), col()],
            },
          ] as DashboardRow[],
        },
        {
          data: [
            {
              size: DashboardWidgetRowSizes.MEDIUM,
              columns: [col(), col(), col()],
            },
            {
              size: DashboardWidgetRowSizes.MEDIUM,
              columns: [col(), col(), col()],
            },
          ] as DashboardRow[],
        },
        {
          data: [
            {
              size: DashboardWidgetRowSizes.MEDIUM,
              columns: [col(), col(), col(), col()],
            },
            {
              size: DashboardWidgetRowSizes.MEDIUM,
              columns: [col(), col(), col(), col()],
            },
          ] as DashboardRow[],
        },
        {
          data: [
            {
              size: DashboardWidgetRowSizes.SMALL,
              columns: [col(), col(), col(), col()],
            },
            { size: DashboardWidgetRowSizes.LARGE, columns: [col()] },
          ] as DashboardRow[],
        },
        {
          data: [
            { size: DashboardWidgetRowSizes.MEDIUM, columns: [col()] },
            { size: DashboardWidgetRowSizes.MEDIUM, columns: [col()] },
          ] as DashboardRow[],
        },
        {
          data: [
            { size: DashboardWidgetRowSizes.LARGE, columns: [col()] },
          ] as DashboardRow[],
        },
        {
          data: [
            { size: DashboardWidgetRowSizes.MEDIUM, columns: [col(), col()] },
          ] as DashboardRow[],
        },
      ],
    }
  },
  computed: {
    cols(): '12' | '3' {
      return this.$vuetify.breakpoint.xs ? '12' : '3'
    },
  },
  created() {
    if (this.value) {
      this.model = this.value
    }
  },
})
</script>
