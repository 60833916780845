import Vue from 'vue'

import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import DashboardFormLayoutInput from '../components/DashboardFormLayoutInput.vue'
import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import i18n from '@/plugins/i18n'

Vue.component('DashboardFormLayoutInput', DashboardFormLayoutInput)
/**
 * Dashboard Create intent
 */
export default class DashboardCreate extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.DASHBOARD_CREATE
  }

  /**
   * Trigger a form event
   * @param payload
   */
  run(payload: any): any {
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
      is: 'DashboardCreateWindow',
      title: i18n.t('intents.dashboard_create.dialog_title'),
      width: 900,
    })
  }
}
