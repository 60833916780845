import { ContextFilter } from '@/tt-widget-components'

import { ValidQueryParams } from '../types'
import { AttributeName } from '@/tt-widget-components/lib/names'

/**
 * Extract context filter values from query param and create valid context filters
 */
export const convertQueryParamsToContextFilters = (
  queryParams: ValidQueryParams,
): ContextFilter[] => {
  return [
    {
      attribute: AttributeName.REGION_ATTRIBUTE,
      operator: queryParams.context_regions_operator,
      value: queryParams.context_regions,
    } as ContextFilter,
    {
      attribute: AttributeName.ACCOUNT_ATTRIBUTE,
      operator: queryParams.context_accounts_operator,
      value: queryParams.context_accounts,
    } as ContextFilter,
    {
      attribute: AttributeName.DATE_RANGE_ATTRIBUTE,
      operator: queryParams.context_daterange_operator,
      value: queryParams.context_daterange,
    } as ContextFilter,
  ].filter((filter) => filter.operator || filter.value)
}
