<template>
  <ActionContextualMenu :menu="menu">
    <v-card
      class="level1 pa-0 ma-0 elevation-0 text-left"
      v-bind="$attrs"
      @click="$emit('click')"
    >
      <v-img
        v-if="hasBannerImage"
        class="white--text align-end"
        :height="120"
        :style="{
          opacity: $vuetify.theme.dark ? 0.5 : 0.8,
        }"
        width="100%"
        :src="bannerImage"
      >
        <div class="card-screen" />
      </v-img>
      <WidgetCardInfoList>
        <v-icon slot="icon" :color="color" v-text="icon" />
        <span slot="title" v-text="$t(item.name)" />
        <span v-if="hasMenuItems" slot="right-action">
          <v-menu class="text-right">
            <template #activator="{ on }">
              <v-btn x-small icon text v-on="on">
                <v-icon
                  :color="menuColor"
                  x-small
                  @click="buildActions()"
                  v-text="'more_horiz'"
                />
              </v-btn>
            </template>
            <v-list v-if="isLoading" style="width: 205px">
              <template v-for="index in 3">
                <v-skeleton-loader :key="`loader-${index}`" type="list-item" />
                <v-divider v-if="index < 3" :key="`divider-${index}`" />
              </template>
            </v-list>
            <AppActionMenu
              v-else
              :menu="menu"
              divider
              @click:create-sub="trackCreateFolderEvent"
            ></AppActionMenu>
          </v-menu>
        </span>
      </WidgetCardInfoList>
    </v-card>
  </ActionContextualMenu>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import ActionContextualMenu from '@/tt-app-layout/components/ActionContextualMenu.vue'
import { ActionMenuItem } from '@/tt-app-layout'
import { CreateFolderInActionMenu } from '@/plugins/o11n'
import { modularManager } from '@/tt-app-modular'
import { WidgetCategoryModel, WidgetStoreModel } from '@/tt-widget-factory'
import WidgetCardInfoList from './WidgetCardInfoList.vue'
import { Resources } from '@/tt-entity-design/src/types'
import { Routes } from '../enumRoutes'

export default Vue.extend({
  name: 'CategoryCard',
  components: {
    ActionContextualMenu,
    WidgetCardInfoList,
  },
  inheritAttrs: false,
  props: {
    item: {
      type: Object as PropType<WidgetCategoryModel>,
      default: undefined,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    displayMenuItems: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menuItems: [],
      isLoading: false,
      /**
       *  @todo: to review
       * modularManager.getMenuItems() is used for displaying actions in listing and widget view,
       * but it has too many differences to be used on both places.
       */
      displayActions: [
        {
          action: {
            type: 'route',
            props: {
              name: Routes.EXPLORE_CATEGORY,
              params: { categoryId: this.item.uid },
            },
          },
          color: 'blue',
          icon: 'mdi-folder-open',
          text: 'common.open.btn',
        },
      ] as ActionMenuItem[],
    }
  },
  computed: {
    icon(): string {
      return this.item.meta?.icon || 'mdi-folder-multiple'
    },
    color(): string {
      return this.item.meta?.color || 'green lighten-4'
    },
    bannerImage(): string {
      return this.item.meta?.bannerImage
    },
    count(): number {
      return WidgetStoreModel.query().where('category', this.item.uid).count()
    },
    menu(): { items: ActionMenuItem[] } {
      return { items: this.menuItems }
    },
    menuColor(): string {
      return this.$vuetify.theme.dark ? 'white' : '#666'
    },
    hasMenuItems(): boolean {
      return this.displayMenuItems
    },
    hasBannerImage(): boolean {
      return !!this.item?.meta?.bannerImage
    },
  },
  methods: {
    buildActions() {
      this.isLoading = true
      modularManager
        .getMenuItems(
          this.$appContext,
          'Categories.actions',
          this.item,
          Resources.DATA_VIEW_CATEGORIES,
        )
        .then((menuItems) => {
          this.menuItems = [...this.displayActions, ...menuItems]
          this.isLoading = false
        })
    },
    trackCreateFolderEvent(): void {
      this.$analytics.track(CreateFolderInActionMenu.create())
    },
  },
})
</script>
