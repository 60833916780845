import i18n from '@/plugins/i18n'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { EventManager } from '@/tt-event-manager'
import { WidgetStoreModel } from '@/tt-widget-factory'

const clearWidgetStore = (): void => {
  WidgetStoreModel.setSelected(null)
  WidgetStoreModel.setNew(null)
}

const confirm = (eventManager, message: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
      message,
      accept: () => resolve(),
      cancel: () => reject(),
    })
  })
}

export const askConfirmationToDiscardChanges = (
  eventManager: EventManager,
): Promise<void> =>
  confirm(eventManager, i18n.tc('widgets.discard_changes_question'))

export const askConfirmationToClear = (
  eventManager: EventManager,
  needConfirmation: boolean,
): Promise<void> => {
  if (!needConfirmation) {
    clearWidgetStore()

    return Promise.resolve()
  }

  const message = i18n.tc('widgets.discard_changes_question')

  return confirm(eventManager, message).then(
    () => clearWidgetStore(),
    () => Promise.reject(),
  )
}
