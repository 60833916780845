import {
  WidgetCategoryInterface,
  WidgetProviderInterface,
} from '@/tt-widget-factory/services/widget-collections/types'
import { WidgetStoreInterface } from '@/tt-widget-factory'
import { WidgetCategoryModelMeta } from '@/tt-widget-factory/WidgetCategoryModel'

/**
 * Widgets
 */
export type WidgetCollectionProviderInterface = {
  name: string
  label: string
  uid?: string
  meta?: WidgetCategoryModelMeta
  categories?: WidgetCategoryInterface[]
  views: WidgetStoreInterface[]
}

export default class MarketplaceWidgetProvider
  implements WidgetProviderInterface
{
  views: WidgetStoreInterface[]
  categories: WidgetCategoryInterface[]
  name: string
  label: string

  /**
   * Widget store
   * @param views
   * @param category
   */
  constructor(provider: WidgetCollectionProviderInterface) {
    this.name = provider.name
    this.label = provider.label
    const rootCategory = `${provider.name}-root`

    // We lock the views into their categories
    this.views = provider.views.map((item) => {
      const category = item.category
        ? `${this.name}---${item.category}`
        : rootCategory
      return { ...item, category, isFromMarketplace: true }
    })

    // We lock the categories into their categories
    if (provider.categories) {
      this.categories = provider.categories.map((item) => {
        const parentCategory = item.parentCategory
          ? `${this.name}---${item.parentCategory}`
          : rootCategory
        return { ...item, parentCategory, isFromMarketplace: true }
      })
    } else {
      this.categories = []
    }

    // Add the root category
    this.categories.push({
      uid: rootCategory,
      name: provider.label,
      meta: provider.meta,
      isFromMarketplace: true,
    })
  }

  /**
   * All categories
   */
  async getAllCategories() {
    return this.categories
  }

  /**
   * Get all views
   */
  async getAllViews() {
    return this.views
  }

  getProviderLabel(): string {
    return this.label
  }

  getProviderName(): string {
    return this.name
  }
}
