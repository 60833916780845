import { WidgetStoreModel, WidgetArchiveModel } from '@/tt-widget-factory'
import { AppContext } from '@/tt-app-context'

export interface SearchProviderItemInterface {
  label: string
  widget?: object
  title: string
  description: string
  icon: string
  color: string
  archived: boolean
  route: string
  priority: number
}

export default class WidgetSearchProvider {
  private readonly appContext: AppContext

  constructor(appContext: AppContext) {
    this.appContext = appContext
  }

  /**
   * Search
   * @param term
   */
  public search(term: string): SearchProviderItemInterface[] {
    if (!term) {
      return []
    }
    term = term.toLowerCase()
    return [...this.searchWidgets(term), ...this.searchArchiveWidgets(term)]
  }

  public searchWidgets(term: string) {
    return WidgetStoreModel.query()
      .where((model: WidgetStoreModel) => {
        return model.widget?.title?.toLowerCase().includes(term) ?? false
      })
      .limit(30)
      .all()
      .map((model: WidgetStoreModel) => {
        const widgetType =
          this.appContext.widgetServices.widgetManager.getWidgetByName(model.is)
        // Score search results
        let score = 0
        // Show dashboard first
        if (model.is === 'DashboardWidget') {
          score += 10
        }
        // Show owner by me first
        if (model.ownedByMe) {
          score += 5
        }

        return {
          label: model.title,
          description: model.widget.description,
          icon: widgetType.config.icon,
          color: widgetType.config.color,
          archived: false,
          priority: score,
          route: `/insight-studio/view/${model.uid}`,
        } as SearchProviderItemInterface
      })
      .sort(
        (a: SearchProviderItemInterface, b: SearchProviderItemInterface) => {
          return a.priority > b.priority ? -1 : 1
        },
      )
  }
  public searchArchiveWidgets(term: string) {
    return WidgetArchiveModel.query()
      .where((model: WidgetArchiveModel) => {
        return model.widget?.title?.toLowerCase().includes(term) ?? false
      })
      .limit(30)
      .all()
      .map((model: WidgetArchiveModel) => {
        const widgetType =
          this.appContext.widgetServices.widgetManager.getWidgetByName(model.is)
        // Score search results 0
        const score = 0
        return <SearchProviderItemInterface>{
          is: model.widget.is,
          widget: model,
          meta: model.widget.meta,
          title: model.widget.title,
          description: model.widget.description,
          uid: model.widget.uid,
          label: model.title,
          icon: widgetType.config.icon,
          color: widgetType.config.color,
          archived: true,
          priority: score,
          route: `/insight-studio/view/${model.uid}`,
        }
      })
  }
}
