import i18n from '@/plugins/i18n'

import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetCategoryModel } from '@/tt-widget-factory'
import { getInsightRoute } from '../utils'

interface RemoveCategoryModel extends WidgetCategoryModel {
  isActionMenuToolbar: boolean
}

export default class CategoryRemove extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.CATEGORY_REMOVE
  }

  /**
   * Trigger a form event
   * @param payload
   */
  async run(payload: RemoveCategoryModel) {
    const showSuccessFeedback = () => {
      const message = i18n.t('intents.category_remove.success_msg')
      this.appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_SUCCESS,
        { message },
      )
    }

    const showErrorFeedback = (e) => {
      console.error('Widget Archive Error', e)
      const message = i18n.t('intents.category_remove.error_msg')
      this.appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_ERROR,
        { message },
      )
    }

    const onRemoveSuccess = () => {
      showSuccessFeedback()

      if (payload.isActionMenuToolbar) {
        const routeParams = ['explore', `${payload.parentCategory}`]
        this.appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.GO_TO,
          getInsightRoute(routeParams),
        )
      }
    }

    const removeCategory = () => {
      return this.widgetPersister
        .removeCategory(payload)
        .then(onRemoveSuccess, showErrorFeedback)
    }

    const onConfirm = async () => {
      return removeCategory()
    }

    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
      is: 'CategoryDeleteFolderDialog',
      title: i18n.t('intents.category_remove.dialog_title'),
      width: 400,
      props: {
        message: i18n.t('intents.category_remove.dialog_msg'),
        onConfirm,
      },
    })
  }
}
