<template>
  <v-toolbar
    v-if="items && items.length"
    dense
    short
    flat
    class="toolbar toolbar2"
    style="margin-left: -16px"
  >
    <v-btn
      v-if="isRoot || isNotRootIsMobile"
      small
      icon
      text
      circle
      :style="
        isNotRootIsMobile ? 'transform: rotate(90deg)' : 'margin-right: 6px'
      "
      @click="goBack"
    >
      <v-icon small
        >{{
          isNotRootIsMobile ? 'mdi-subdirectory-arrow-left' : 'mdi-arrow-left'
        }}
      </v-icon>
    </v-btn>
    <OverflowBox>
      <template #default>
        <template v-for="(item, index) in items">
          <span :key="`${item.label}-${index}`" class="breadcrumb">
            <span :key="`${item.label}-${index}`" class="pl-1">
              <a
                v-if="index < items.length - 1 && !isMobile"
                @click="clickOn(item)"
                v-text="$t(item.label)"
              />
              <span v-if="index < items.length - 1 && !isMobile" class="pl-1">
                &nbsp;/&nbsp;
              </span>
              <span
                v-if="index === items.length - 1"
                style="font-weight: 500"
                class="pl-1"
                v-text="$t(item.label)"
              />
            </span>
          </span>
        </template>
      </template>
      <template #overflow>
        <span
          style="font-weight: 500"
          class="pl-1"
          v-text="$t(items[items.length - 1].label)"
        />
      </template>
    </OverflowBox>
  </v-toolbar>
</template>

<script lang="ts">
import Vue from 'vue'
import {
  getBreadCrumbs,
  getInsightRoute,
  BreadCrumbItem,
} from '@/apps/app.tracktik.insights.studio/utils'
import { omitPushErrors } from '@/plugins/router'
import { Routes } from '../enumRoutes'
import { MY_INSIGHTS_CATEGORY_UID } from '../types'

export default Vue.extend({
  name: 'WidgetExplorerToolbarBreadCrumb',
  props: {},
  computed: {
    items(): BreadCrumbItem[] {
      const { name, params } = this.$route
      const isSharedWithMe = name === Routes.SHARED_WITH_ME

      const categoryId = isSharedWithMe
        ? `${MY_INSIGHTS_CATEGORY_UID}${params.categoryId}`
        : params.categoryId

      return getBreadCrumbs(categoryId, isSharedWithMe)
    },
    isRoot(): boolean {
      return this.items?.length === 1
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs
    },
    isNotRootIsMobile(): boolean {
      return !this.isRoot && this.isMobile
    },
  },
  methods: {
    clickOn(item: any) {
      if (!item) {
        this.$router
          .push(getInsightRoute(['explore']))
          .catch(omitPushErrors(['NavigationDuplicated']))
        return
      }

      this.$router
        .push(getInsightRoute(item.routeParams))
        .catch(omitPushErrors(['NavigationDuplicated']))
    },
    goBack() {
      if (this.isRoot) {
        this.$router.push('/')
      } else {
        this.clickOn(this.items[this.items.length - 2])
      }
    },
  },
})
</script>

<style scoped>
.breadcrumb {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  font-size: 0.8em;
  line-height: 2em;
}
a {
  padding: 0.2em;
  font-weight: 300;
}
a:hover {
  background-color: var(--v-muted-base);
  border-radius: 0.3em;
}
</style>
