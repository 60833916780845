import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import { WidgetCategoryModel, WidgetStoreModel } from '@/tt-widget-factory'
import i18n from '@/plugins/i18n'
import { DialogFormBuilder } from '@/helpers/dialog-form-builder'
import { WidgetCollectionProviderInterface } from '@/apps/app.tracktik.insights.studio/lib/MarketplaceWidgetProvider'
import { cloneDeep } from 'lodash'
import { downloadAsFile } from '@/helpers/downloadAsFile'

export default class CategoryDownloadAsCollection extends WidgetBaseIntent {
  /**
   *
   * @param categoryModel
   * @param data
   */
  static toCollection(
    categoryModel: WidgetCategoryModel,
    data,
  ): WidgetCollectionProviderInterface {
    const collection = {
      categories: [],
      views: [],
      name: data.name,
      uid: data.uid,
      label: data.label,
      meta: {
        icon: categoryModel.meta?.icon ?? null,
        color: categoryModel.meta?.color ?? null,
        bannerImage: categoryModel.meta?.bannerImage ?? null,
      },
    } as WidgetCollectionProviderInterface

    categoryModel = cloneDeep(categoryModel)
    const parentCategoryUid = categoryModel.uid
    categoryModel.uid = null
    CategoryDownloadAsCollection.addCategoryToCollection(
      data.name,
      collection,
      categoryModel,
      parentCategoryUid,
    )

    return collection
  }

  static addCategoryToCollection(
    providerName: string,
    collection: WidgetCollectionProviderInterface,
    categoryModel: WidgetCategoryModel,
    parentCategoryUid: string,
  ) {
    let counter = 1000
    const uidPrefix = categoryModel.uid
      ? categoryModel.uid
      : `${providerName}-root`
    // Add the widgets
    WidgetStoreModel.query()
      .where('category', parentCategoryUid)
      .all()
      .forEach((widget: WidgetStoreModel) => {
        const widgetCopy = cloneDeep(widget)

        // Clean up and change a few elements
        delete widgetCopy.$id
        delete widgetCopy.meta
        delete widgetCopy.provider
        delete widgetCopy.ownedByMe
        delete widgetCopy.widget.category
        widgetCopy.uid = categoryModel.uid
        widgetCopy.widget.uid =
          widgetCopy.uid = `${uidPrefix}|widget|${counter}`
        widgetCopy.category = categoryModel.uid
        widgetCopy.provider = providerName
        collection.views.push(widgetCopy)
        counter++
      })

    counter = 1
    // Add the sub categories
    WidgetCategoryModel.query()
      .where('parentCategory', parentCategoryUid)
      .all()
      .forEach((category: WidgetCategoryModel) => {
        const categoryCopy = cloneDeep(category)

        // Clean up and change a few elements
        const parentCategoryCriteria = category.uid
        delete categoryCopy.$id
        delete categoryCopy.meta
        delete categoryCopy.ownedByMe
        categoryCopy.parentCategory = categoryModel.uid
        categoryCopy.uid = `${uidPrefix}|category|${counter++}`
        collection.categories.push(categoryCopy)
        CategoryDownloadAsCollection.addCategoryToCollection(
          providerName,
          collection,
          categoryCopy,
          parentCategoryCriteria,
        )
        counter++
      })
  }

  /**
   * Download as a collection
   * @param categoryModel
   * @param data
   */
  static downloadAsCollection(categoryModel: WidgetCategoryModel, data) {
    const json = CategoryDownloadAsCollection.toCollection(categoryModel, data)

    downloadAsFile(
      JSON.stringify(json),
      `${json.name.replace(/ /g, '-')}.collection`,
      'JSON',
    )
  }

  getEventName(): string {
    return WidgetEditorEvents.CATEGORY_DOWNLOAD_AS_COLLECTION
  }

  run(categoryModel: WidgetCategoryModel): void {
    const { eventManager, authModule } = this.appContext
    const title = i18n.tc('action_menu_item.download_as_collection')
    const translateFn = (attrPath) => i18n.tc(attrPath)

    new DialogFormBuilder(title, 'CategoryDownloadForm', {
      eventManager,
      i18n,
    })
      .addText('label', 'category.download_form.label', {
        defaultValue: categoryModel.name,
      })
      .addText('name', 'category.download_form.name', {
        defaultValue: categoryModel.name.replace(' ', '-').toLowerCase(),
      })
      .setTranslateFunction(translateFn)
      .onSubmit(async (data: any): Promise<any> => {
        CategoryDownloadAsCollection.downloadAsCollection(categoryModel, data)
        return {}
      })
      .displayDialog()
  }
}
