import i18n from '@/plugins/i18n'

import {
  WidgetEditorEvents,
  WidgetExploreEvents,
  WidgetViewEnum,
} from '@/apps/app.tracktik.insights.studio/types'
import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetStoreModel } from '@/tt-widget-factory/types'
import { WidgetReference } from '@/tt-widget-factory'
import { WidgetName } from '@/tt-widget-components/lib/names'

/**
 * Dashboard Create intent
 */
export default class WidgetArchive extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_ARCHIVE
  }

  /**
   * Trigger a form event
   * @param payload
   */
  async run(payload: WidgetReference) {
    const widgetType =
      payload.is === WidgetName.DASHBOARD_WIDGET
        ? WidgetViewEnum.DASHBOARD
        : WidgetViewEnum.WIDGET

    const showSuccessFeedback = () => {
      const message = i18n.t(`intents.${widgetType}_archive.archived`)
      this.appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_SUCCESS,
        { message },
      )
    }

    const showErrorFeedback = (e) => {
      console.error('Widget Archive Error', e)
      const message = i18n.t(`intents.${widgetType}_archive.failed_to_archive`)
      this.appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_ERROR,
        { message },
      )
    }

    const isWidgetPersisted = () =>
      this.widgetPersister.getDataViewByUid(payload.uid).then(Boolean)

    const onArchiveSuccess = () => {
      showSuccessFeedback()

      const event = window.location.href.includes(payload.uid)
        ? WidgetExploreEvents.ROUTE_TO_INSIGHTS
        : WidgetEditorEvents.CATEGORY_UPDATED

      this.appContext.eventManager.dispatchEvent(event)
    }

    const archiveWidget = () => {
      this.widgetPersister
        .archive(payload)
        .then(onArchiveSuccess)
        .catch(showErrorFeedback)
    }

    const removeWidgetFromStore = () => {
      const widget = WidgetStoreModel.find(payload.uid)
      if (widget) {
        WidgetStoreModel.delete(payload.uid)
      }
    }

    const onConfirm = async () => {
      if (await isWidgetPersisted()) {
        archiveWidget()
      } else {
        removeWidgetFromStore()
        onArchiveSuccess()
      }
    }

    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
      is: 'WidgetArchiveDialog',
      title: i18n.tc(`intents.${widgetType}_archive.title`),
      width: 400,
      props: {
        message: i18n.tc(`intents.${widgetType}_archive.archive_this_q`),
        onConfirm,
      },
    })
  }
}
