<template>
  <SharableForm
    v-if="ownerId"
    :acl-rule="aclRule"
    :owner-id="+ownerId"
    :loading="loading"
    @submit="submit"
    @close="$emit('close')"
  />
  <TLoadingWave v-else class="loading-bar" />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import { AclRule, SharableForm } from '@/tt-widget-sharable'
import {
  displayErrorMessages,
  parseErrorMessages,
} from '@/tt-widget-entity-flow/intents/helpers'
import { LayoutWindowEvent } from '@/tt-app-layout'

import { OnSubmitFn, MetaType } from '../intents/WidgetShare'

export default Vue.extend({
  name: 'EntityShareForm',
  components: { SharableForm },
  props: {
    fetchMetadata: {
      type: Function as PropType<() => Promise<MetaType>>,
      required: true,
    },
    resourceName: {
      type: String,
      required: true,
    },
    onSubmit: {
      type: Function as PropType<OnSubmitFn>,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      widgetMeta: null as null | MetaType,
    }
  },
  computed: {
    aclRule(): AclRule | null {
      return this.widgetMeta?.aclRule
    },
    ownerId(): number | string | null {
      return this.widgetMeta?.ownedBy?.id
    },
  },
  created() {
    this.loadWidget()
  },
  methods: {
    loadWidget() {
      this.fetchMetadata().then((widgetMeta) => {
        this.widgetMeta = widgetMeta
        this.loading = false
      })
    },
    async submit(aclRule: AclRule) {
      const stopLoading = () => (this.loading = false)
      const onSuccess = () => {
        this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_SUCCESS, {
          message: this.$t('intents.share.shared_successfully'),
        })
        this.loadWidget()
      }
      const handleError = (error) => {
        this.$crash.captureException(error)

        const messages = parseErrorMessages({
          actionName: 'share',
          error,
          resourceName: this.resourceName,
        })
        displayErrorMessages(messages, this.$appContext.eventManager)
      }

      this.loading = true

      await this.onSubmit(aclRule, this.$analytics)
        .then(onSuccess)
        .catch(handleError)
        .finally(stopLoading)
    },
  },
})
</script>

<style scoped>
.loading-bar {
  margin-top: 75px;
}
</style>
