<template>
  <v-card flat class="fill-height v-sheet--tile d-flex flex-column">
    <div class="pa-3 primary lighten-2" style="border-radius: 0">
      <v-text-field
        v-model="search"
        :label="$t('data_doc.search_objects')"
        dark
        flat
        dense
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
      ></v-text-field>
    </div>
    <v-card-text v-if="itemList" class="fill-height" style="overflow: auto">
      <v-treeview
        :items="itemList"
        :search="search"
        :filter="filter"
        activatable
        :open.sync="open"
      >
        <template #label="{ item }">
          <DataDocItem :item="item" />
        </template>
        <template #prepend="{ item }">
          <v-icon small :color="item.color" v-text="item.icon"></v-icon> &nbsp;
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from 'vue'
import { buildTree, DataDocTreeItem } from '../lib/data-doc'
import DataDocItem from '../components/DataDocItem.vue'

export default Vue.extend({
  name: 'DataDoc',
  components: {
    DataDocItem,
  },
  data() {
    return {
      open: [0],
      search: '',
    }
  },
  computed: {
    metadatasReady(): boolean {
      return this.$appContext.isReady()
    },
    filter(): (item: DataDocTreeItem, search: string) => boolean {
      return (item: DataDocTreeItem, search: string) => {
        if (!item.search) {
          return false
        }
        return (
          item.search.toLowerCase().indexOf(search.trim().toLowerCase()) > -1
        )
      }
    },
    itemList(): DataDocTreeItem[] {
      if (!this.metadatasReady) {
        return null
      }
      return buildTree()
    },
  },
})
</script>
<style>
.v-treeview-node__root {
  min-height: 30px;
}

.v-treeview-node--leaf {
  margin-left: 10px;
}
</style>
