import { uniqueId } from 'lodash'

import { allowedFunctions, relativeDates } from '@/tt-tql-inputs/src/lang/types'
import { attributeTypeIconMap } from '@/tt-widget-components/lib/attribute-icons'
import resourceMetaManager from '@/tt-widget-factory/services/resource-meta'
import {
  FieldTypes,
  Resource,
} from '@/tt-widget-factory/services/resource-meta/types'

export const iconsMaps = attributeTypeIconMap

export type DataDocTreeItem = {
  name: string
  icon: string
  type?: string
  color: string
  id: string | number
  children?: DataDocTreeItem[]
  search?: string
}

export function buildTree(): DataDocTreeItem[] {
  return [
    {
      name: 'data_doc.data_models',
      icon: 'mdi-database',
      type: 'resourceFolder',
      color: 'orange',
      id: '_1',
      children: buildObjectTree(),
    },
    {
      name: 'data_doc.functions',
      id: '_2',
      type: 'resourceFolder',
      icon: 'mdi-function-variant',
      color: 'purple',
      children: buildFunctionTree(),
    },
    {
      name: 'data_doc.relative_dates',
      icon: 'mdi-calendar-range',
      color: 'blue',
      id: '_3',
      children: buildRelativeDateTree(),
    },
  ]
}

export function buildFunctionTree(): DataDocTreeItem[] {
  const list = [] as any[]
  allowedFunctions.forEach((item) => {
    list.push({
      id: item,
      name: item,
      icon: 'mdi-function-variant',
      color: 'purple',
      search: item,
      type: 'relativeDate',
    } as DataDocTreeItem)
  })
  return list
}

/**
 * Relative dates
 */
export function buildRelativeDateTree(): DataDocTreeItem[] {
  const list = [] as DataDocTreeItem[]
  relativeDates.forEach((item) => {
    list.push({
      id: item,
      name: item,
      icon: 'mdi-calendar-range',
      color: 'blue',
      search: item,
      type: 'relativeDate',
    } as DataDocTreeItem)
  })
  return list
}

/**
 * Build the list of objects
 */
export function buildObjectTree(): DataDocTreeItem[] {
  const list: DataDocTreeItem[] = []
  const modules = getResourcesByModules()
  for (const modName in modules) {
    const modItems = modules[modName]

    const moduleItem = {
      id: `Module.${modName}`,
      name: modName,
      icon: 'mdi-folder-multiple-outline',
      color: 'orange darken-2',
      type: 'module',
    } as DataDocTreeItem

    const children: DataDocTreeItem[] = Object.keys(modItems).map(
      (resourceName) => {
        const name = resourceName.replace(/-/g, '_')
        return {
          id: name,
          type: 'resource',
          icon: 'mdi-database',
          search: name,
          color: 'blue darken-2',
          name: name,
          children: buildAttributeTree(resourceName, '', true),
        }
      },
    )
    if (children && children.length) {
      moduleItem.children = children
    }

    list.push(moduleItem)
  }

  return list
}

/**
 * Build attribute tree
 * @param resourceName
 */
export function buildAttributeTree(
  resourceName: string,
  prefix = '',
  dig = false,
): DataDocTreeItem[] {
  const skips = ['resourceType', 'uri']
  const attrs = resourceMetaManager.getAttributes(resourceName, 0)
  if (!attrs) {
    return []
  }

  return Object.keys(attrs)
    .map((attrName) => {
      const attribute = resourceMetaManager.getAttribute(resourceName, attrName)
      if (!attribute) {
        return
      }
      if (skips.includes(attrName)) {
        return
      }

      const subType = attribute.type ? attribute.type : FieldTypes.Label

      const treeItem = {
        id: uniqueId(),
        name: prefix + attrName,
        type: 'attribute',
        icon: iconsMaps[subType],
        subType,
        options: attribute.enum,
        label: attribute.labels.label,
        color: null,
        item: attribute,
      } as DataDocTreeItem

      if (dig && subType == FieldTypes.Relation) {
        const attrs = buildAttributeTree(
          attribute.relation!.resource,
          attrName + '.',
          false,
        )

        if (attrs && attrs.length > 0) {
          treeItem.children = attrs
        }
      }
      return treeItem
    })
    .filter((item) => item)
}

/**
 * Get the resource map
 */
export function getResourceMap() {
  const resourceNames = resourceMetaManager.getResourceNames()

  const resources: { [k: string]: Resource } = {}
  resourceNames.forEach((name: string) => {
    const resource = resourceMetaManager.getResource(name)
    if (!resource) {
      return
    }
    resources[name] = resource
  })
  return resources
}

/**
 * Get resources by modules
 */
export function getResourcesByModules() {
  const modules: {
    [k: string]: {
      [k: string]: Resource
    }
  } = {}
  const resources = getResourceMap()

  Object.keys(getResourceMap()).forEach((resourceName: string) => {
    const item = resources[resourceName]

    const moduleName = item.module
      ? [item.domain, item.module]
          .filter((src) => src && src.name)
          .map((src) => src.name)
          .join(' / ')
      : 'Other'

    if (!modules[moduleName]) {
      modules[moduleName] = {}
    }
    modules[moduleName][resourceName] = item
  })

  const out = {}
  Object.keys(modules)
    .sort()
    .forEach((key: string) => {
      out[key] = modules[key]
    })

  return out
}
