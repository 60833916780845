<template>
  <app-window v-if="widget" :value="true" class="fill-height">
    <widget-container height="400" class="d-flex flex-column chat-data-view-container level1">
      <widget-factory :widget="widget" :container="{height: 500}" :showTQL="true" ref="widget"/>
    </widget-container>
    <ChatBotDataViewToolbar @analyse="analyse" :message="message"/>
  </app-window>
</template>
<script lang="ts">
import NQLAggregationAdapter from "@/tt-tql-inputs/src/nql/NQLAggregationAdapter";
import ChatBotDataViewToolbar from "@/tt-app-extensions/chat-bot/components/ChatBotDataViewToolbar.vue";
import ChatVue from "@/tt-app-extensions/chat-bot/components/ChatVue";
import {PropType} from "vue";
import {ChatMessage} from "@/tt-app-extensions/chat-bot/types";
import TQLQueryWidgetHook from "@/tt-widget-components/widgets/TQLQuery/TQLQueryWidgetHook";
import {ConfirmInterface, LayoutWindowEvent} from "@/tt-app-layout";

export default ChatVue.extend({
  name: 'ChatBotDataView',
  components: {
    ChatBotDataViewToolbar,
  },
  props: {
    message: {
      type: Object as PropType<ChatMessage>,
      required: true,
    },
  },
  created() {
    if (this.message.data?.tql ?? null) {
      this.widget = NQLAggregationAdapter.fromTQL(this.message.data.tql, this.message.data.question)
    }
  },
  methods: {
    analyse() {
      this.$appContext.eventManager.dispatchEvent(LayoutWindowEvent.CONFIRM, {
        message: 'You are about to share this data to our AI assistant',
        accept: () => {
          this.analyseDo()
        }
      } as ConfirmInterface)
    },
    analyseDo() {
      //@ts-ignore
      const tqlWidget = this.$refs.widget.hook as TQLQueryWidgetHook
      const data = tqlWidget.state.data
      this.session.analyse(data, tqlWidget.state.tql)
    }
  },
  data() {
    return {
      widget: null,
    }
  },
})
</script>
<style scoped>

.chat-data-view-container {
  border-radius: 10px;
  height: 450px;
  padding: 5px;
  margin: 15px 0 15px 0;
}
</style>
<style>
.chat-data-view-container .widget-title {
  font-size: 0.8em;
}
</style>
