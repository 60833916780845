<template>
  <div>
    <slot/>
  </div>
</template>
<script lang="ts">
import Vue, {PropType} from 'vue'
import ChatSession, {ChatAgent, Conversation} from "@/tt-app-extensions/chat-bot/ChatSession";


export default Vue.extend({
  name: 'ProvideChatSession',
  data() {
    return {
      session: null
    }
  },
  provide() {
    return {
      chatSession: () => this.session,
      messages: () => this.session.messages
    }
  },
  props: {
    chatAgent: {
      type: Object as PropType<ChatAgent>,
      required: true
    },
    conversation: {
      type: Object as PropType<Conversation>,
      required: true,
    },
  },
  created() {

    const me = this.$auth.getUserProfile()
    const context = {
      employeeId: me.id,
      firstName: me.firstName,
      lastName: me.lastName,
      name: me.firstName + ' ' + me.lastName,
      email: me.email,
      phone: me.primaryPhone,
      avatar: me.avatar,
      bearer: this.$auth.getUserToken(),
    }
    const chatSession = new ChatSession(this.$appContext, this.chatAgent, {
      ...this.conversation,
      context: context,
    });
    chatSession.start()
    this.session = chatSession
  }
})
</script>
