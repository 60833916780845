import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { WidgetReference } from '@/tt-widget-factory/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import i18n from '@/plugins/i18n'

/**
 * Dashboard Create intent
 */
export default class WidgetSave extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_SAVE
  }

  /**
   * Trigger a form event
   * @param payload
   */
  async run(payload: WidgetReference) {
    const category = payload.category || null

    await this.widgetPersister
      .save(payload, null, category)
      .then((response) => {
        this.appContext.eventManager.dispatchEvent(
          WidgetEditorEvents.WIDGET_SAVE_COMPLETE,
          {},
        )

        this.appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.SNACK_SUCCESS,
          {
            message: i18n.t('intents.widget_save.saved'),
          },
        )

        return response
      })
      .catch((e) => {
        this.appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.SNACK_ERROR,
          {
            message: i18n.t('intents.widget_save.failed_to_save'),
          },
        )
      })
  }
}
