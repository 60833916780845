<template>
  <v-list-item v-if="canShow" class="navigation-item" @click="gotoModularHook">
    <v-list-item-icon class="mr-4">
      <v-icon
        notranslate
        class="theme--dark grey--text"
        size="20"
        v-text="`mdi-monitor-dashboard`"
      />
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title style="font-weight: 300" v-text="`Modular Hook`" />
    </v-list-item-content>
  </v-list-item>
</template>

<script lang="ts">
import Vue from 'vue'
import { Routes } from '../enumRoutes'

export default Vue.extend({
  name: 'ModularHookButton',
  computed: {
    environment(): string {
      return process.env.NODE_ENV
    },
    canShow(): boolean {
      const isSuperUser = this.$auth.hasPermission('superuser:*')
      const isProdEnvironment = this.environment === 'production'

      return isSuperUser && !isProdEnvironment
    },
  },
  methods: {
    gotoModularHook() {
      this.$router.push({ name: Routes.WIDGET_MODULAR_HOOK })
    },
  },
})
</script>
