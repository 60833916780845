<template>
  <div>
    <v-card flat color="transparent" class="mb-0">
      <v-card-actions class="pb-0">
        <v-list-item class="grow pl-0 pr-0">
          <v-list-item-avatar small>
            <v-img
              class="elevation-6"
              alt=""
              size="30"
              :src="avatar"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title style="opacity:0.8; font-size:0.8em; margin-bottom: 8px; margin-left:8px;">
              {{ name }}
            </v-list-item-title>
            <v-row no-gutters>
              <v-col cols="10">
                <div class="chat-message" :class="(message.error || message.systemAction?'error-loading':'')+' '+color">
                  <slot>
                    <span v-if="noHTML" v-text="messageText"/>
                    <span v-else v-html="messageText"/>
                  </slot>
                </div>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script lang="ts">
import Vue, {PropType} from 'vue'
import MarkdownIt from "markdown-it";
import {AssistantChatMessage, ChatMessage} from "@/tt-app-extensions/chat-bot/types";

function waitForMs(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export default Vue.extend({
  data() {
    return {
      noHTML: true,
      messageText: '',
      widget: null,
      markdown: null as null | MarkdownIt,
    }
  },
  created() {
    this.markdown = new MarkdownIt()
  },
  async mounted() {

    if (!this.message.message) {
      return;
    }
    if (!this.stream) {
      this.messageText = this.message.message
      return
    }
    let text = this.markdown.render(this.message.message).trim()


    // Remove the leading <p> and trailing </p>
    if (text.startsWith('<p>') && text.endsWith('</p>')) {
      text = text.slice(3, -4)
    }
    // Remove the leading <p> and trailing </p>
    if (text.includes('<') && text.includes('>')) {
      this.noHTML = false
      this.messageText = text
      return
    }

    // Test is long
    if (text.length > 150) {
      this.messageText = text
      return
    }

    // We fake typing
    const letters = text.split("");
    let i = 0;
    let random = 15
    while (i < letters.length) {

      if (letters[i] == ' ') {
        random = 60
      } else {
        random = Math.floor(Math.random() * 15);
      }

      await waitForMs(random);
      this.messageText += letters[i]
      i++
    }
    return;
  },
  props: {
    message: {
      type: Object as PropType<AssistantChatMessage | ChatMessage>,
      required: true,
    },
    stream: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'blue'
    },
    name: {
      type: String,
      default: 'Assistant'
    },
    avatar: {
      type: String,
      default: 'https://pics.craiyon.com/2023-09-13/e538438db47f4343918f2fcc9d18a0a3.webp'
    }
  },
})

</script>
<style scoped>
.chat-message {
  border-radius: 10px;
  padding: 10px 16px 10px 16px;
  background: white;
  font-size: 0.85em;
  display: inline-block;
  line-height: 1.5;
}

.chat-message.blue {
  background: #2196f3;
  color: white;
}

.theme--dark .chat-message.blue {
  background: #2196f3;
  color: white;
  opacity: 0.8;
}


.chat-message.white {
  background: white;
  color: #222;
}

.theme--dark .chat-message.white {
  background-color: #111 !important;
  color: #ccc;
  opacity: 0.8;
}

.chat-message.error-loading {
  border: 1px dashed #f44336;
  color: #f44336;
  opacity: 0.5;
}


.chat-message-user {
  background: #1d3a66;
  color: white;
}
</style>
