<template>
  <div
    v-if="metadatasReady"
    class="fill-height"
    :class="{ 'search-results': isWidgetSearchNav }"
  >
    <template v-if="views && views.length">
      <template v-if="viewMode === 'LIST'">
        <v-list dense>
          <div
            v-for="(item, index) in views"
            :key="`view.${index}.${item.uid}-list-title`"
          >
            <WidgetSearchResultsDraggable
              v-if="isDraggable"
              :view="item"
              @click="$emit('click', item)"
            >
              <template #default>
                <!-- Main Tile -->
                <WidgetListTile
                  :item="item"
                  :preview-on-mouse-over="previewOnMouseOver"
                  :show-action-menu="showActionMenu"
                  :is-draggable="isDraggable"
                  @click="$emit('click', item)"
                />
                <v-divider />
              </template>
            </WidgetSearchResultsDraggable>
            <div v-else>
              <WidgetListTile
                :item="item"
                :preview-on-mouse-over="previewOnMouseOver"
                :show-action-menu="showActionMenu"
                :is-draggable="isDraggable"
                @click="$emit('click', item)"
              />
              <v-divider />
            </div>
          </div>
        </v-list>
      </template>
      <template v-else>
        <v-row>
          <v-col
            v-for="(item, index) in views"
            :key="`view.${index}.${item.uid}-card`"
            :cols="colSize"
            @click="$emit('click', item)"
          >
            <WidgetSearchResultsDraggable
              v-if="isDraggable"
              :view="item"
              @click="$emit('click', item)"
            >
              <template #default>
                <div>
                  <WidgetCard :item="item" />
                </div>
              </template>
            </WidgetSearchResultsDraggable>
            <div v-else>
              <WidgetCard :item="item" />
            </div>
          </v-col>
        </v-row>
      </template>
    </template>
    <infinite-loading :identifier="loaderId" @infinite="getViews">
      <span slot="no-more">
        <!-- end-of-result -->
      </span>

      <span slot="no-results">
        <v-row v-if="showMessageOnEmpty" class="fill-height">
          <v-col class="fill-height">
            <v-sheet class="fill-height text-center transparent">
              <v-icon size="400" color="grey lighten-3">mdi-magnify</v-icon>
              <h1
                style="font-weight: 100"
                class="pa-5"
                v-text="$t('common.no_results')"
              />
            </v-sheet>
          </v-col>
        </v-row>
      </span>
    </infinite-loading>
  </div>
  <SkeletonWidgets v-else :cols="colSize" />
</template>
<script lang="ts">
import Vue from 'vue'

import InfiniteLoading from 'vue-infinite-loading'

import { WidgetCollectionSearchProviderInterface } from '@/tt-widget-factory/services/widget-collections/types'
import WidgetCard from './WidgetCard.vue'
import WidgetListTile from './WidgetListTile.vue'
import WidgetSearchResultsDraggable from './WidgetSearchResultsDraggable.vue'
import { widgetCollectionManager } from '@/apps/app.tracktik.insights.studio/setup'
import DataViewProvider from '../lib/DataViewProvider'
import { PageSettings } from '../lib/DataViewProvider/types'
import { CategoryNames } from '../types'
import uniqBy from 'lodash/uniqBy'

export default Vue.extend({
  name: 'WidgetSearchResults',
  components: {
    WidgetCard,
    WidgetListTile,
    InfiniteLoading,
    WidgetSearchResultsDraggable,
  },
  props: {
    previewOnMouseOver: {
      type: Boolean,
      default: false,
    },
    showActionMenu: {
      type: Boolean,
      default: true,
    },
    showMessageOnEmpty: {
      type: Boolean,
      default: true,
    },
    viewMode: {
      type: String,
      default: 'LIST',
    },
    searchProvider: {
      type: Object as () => WidgetCollectionSearchProviderInterface,
      required: true,
    },
    isDraggable: {
      type: Boolean,
      default: true,
    },
    includeStoreResults: {
      type: Boolean,
      default: false,
    },
    isWidgetSearchNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      provider: new DataViewProvider(this.$appContext),
      views: [],
      offset: 0,
    }
  },
  computed: {
    metadatasReady(): boolean {
      return this.$appContext.isReady()
    },
    colSize(): number {
      return {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 2,
      }[this.$vuetify.breakpoint.name]
    },
    loaderId(): string {
      const { $route, searchProvider } = this
      const viewsCount = widgetCollectionManager.getViewsCount(searchProvider)

      return `${$route.path}-${searchProvider.typeFilter}-${searchProvider.searchQuery}-${viewsCount}`
    },
    // TODO FE-591 to be backward compatible, must be removed in the future
    isSpecialCategory(): boolean {
      if (typeof this.searchProvider.categoryId === 'string') {
        const category = this.searchProvider.categoryId as CategoryNames

        return [
          CategoryNames.HR,
          CategoryNames.REVENUE,
          CategoryNames.OPERATIONS,
          CategoryNames.TUTORIALS,
          CategoryNames.CLIENT,
          CategoryNames.FORS,
        ].includes(category)
      }

      return false
    },
  },
  watch: {
    loaderId: 'resetPagination',
  },
  methods: {
    resetPagination() {
      this.offset = 0
      this.views = []
    },
    async getViews({ loaded, complete }) {
      const { offset, searchProvider } = this
      const limit = searchProvider.limit || 20
      const pagination: PageSettings = { limit, offset }

      const newViews = await this.toggleProvier(searchProvider, pagination)

      this.views = [...this.views, ...newViews]
      this.offset += limit
      if (newViews.length !== 0) loaded()
      if (newViews.length < limit) complete()
    },
    async toggleProvier(searchProvider, pagination) {
      // TODO FE-591 to be backward compatible, must be removed in the future
      if (this.isSpecialCategory) {
        const { limit, offset } = pagination

        return widgetCollectionManager.getViews(
          { ...searchProvider, limit },
          { offset },
        )
      }
      if (this.includeStoreResults) {
        const { limit, offset } = pagination
        const storeResult = widgetCollectionManager.getViews(
          { ...searchProvider, limit },
          { offset },
        )
        const apiResult = await this.provider.fetchViews({
          ...searchProvider,
          pagination,
        })

        // TODO uniqBy can be remove after FE-556
        return uniqBy([...apiResult, ...storeResult], 'uid')
      }

      return this.provider.fetchViews({
        ...searchProvider,
        pagination,
      })
    },
  },
})
</script>

<style scoped>
.search-results {
  overflow-y: auto;
}
</style>
