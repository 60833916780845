<template>
  <div class="mt-6" v-if="nextQuestions">
    <v-chip  outlined
            style="display:block"
            v-for="question in nextQuestions"
            :key="question"
            @click="session.ask(question)"
    >
      <v-chip-group class="pa-2">
        <v-icon class="mr-2 mt-1" color="green">mdi-comment-plus-outline</v-icon>
        {{ question }}
      </v-chip-group>
    </v-chip>
  </div>
</template>
<script lang="ts">
import ChatVue from "@/tt-app-extensions/chat-bot/components/ChatVue";

export default ChatVue.extend({
  name: 'ChatBotNextQuestions',
  inject: ['chatSession'],
})
</script>
<style scoped></style>
