<template>
  <div>
    <v-tabs v-model="tabIndex" class="mt-2" left slider-color="orange">
      <v-tab v-text="$t('intents.widget_create.tab.new')" />
      <v-tab v-text="$t('intents.widget_create.tab.copy')" />
      <v-tab v-text="$t('intents.widget_create.tab.copy_from_code')" />
    </v-tabs>
    <div class="level1 tab-content-wrapper">
      <AddWidgetView v-if="tabIndex == 0" @input="createWidget($event)" />
      <div v-if="tabIndex === 1">
        <div class="pa-3 create-copy-header-wrapper">
          <div class="create-copy-header-title">
            <v-subheader
              v-text="$t('intents.widget_create.select_widget_to_copy')"
            />
          </div>
          <v-text-field
            :value="search"
            hide-details
            dense
            append-icon="search"
            clearable
            :placeholder="$t('common.search')"
            @input="debounceSearch"
          />
        </div>
        <div class="px-3 pb-3">
          <WidgetSearchResults
            :search-provider="searchProvider"
            :show-action-menu="false"
            :include-store-results="true"
            :is-draggable="false"
            view-mode="LIST"
            @click="copyWidget($event.widget)"
          />
        </div>
      </div>
      <WidgetCopyFromCode v-if="tabIndex === 2" @click="copyWidget($event)" />
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import uuid from 'uuid/v1'
import AddWidgetView from '@/tt-widget-components/components/AddWidgetView.vue'
import WidgetCopyFromCode from '@/tt-widget-components/components/WidgetCopyFromCode.vue'
import { WidgetCollectionSearchProviderInterface } from '@/tt-widget-factory/services/widget-collections/types'
import {
  WidgetStoreModel,
  WidgetReference,
  WidgetTypeDefinition,
} from '@/tt-widget-factory/types'
import WidgetPersister from '@/apps/app.tracktik.insights.studio/lib/WidgetPersister'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetEditorEvents } from '../types'
import { CreateWidget, CopyWidget, ChooseWidget } from '@/plugins/o11n'
import { WidgetCopyPayload } from '../intents/WidgetCopy'
import { getCurrentRouteCategory } from '../lib/RouterUtils'
import { toWidgetStoreModel } from '../lib/DataViewProvider/normalize-data-view-response'
import { Routes } from '../enumRoutes'
import { omitPushErrors } from '@/plugins/router'
import debounce from 'lodash/debounce'
import { WidgetName } from '@/tt-widget-components/lib/names'

export default Vue.extend({
  name: 'WidgetCreateWindow',
  components: {
    AddWidgetView,
    WidgetCopyFromCode,
  },
  props: {
    widgetPersister: {
      type: Object as PropType<WidgetPersister>,
      default: () => ({}),
    },
  },
  data() {
    return {
      tabIndex: 0,
      search: null,
    }
  },
  computed: {
    searchProvider(): WidgetCollectionSearchProviderInterface {
      return {
        searchQuery: this.search,
        typeFilterExclude: [WidgetName.DASHBOARD_WIDGET],
      }
    },
    myCurrentCategory(): string | null {
      // we only allow creating widget in a category you own
      const currentCategory = getCurrentRouteCategory(this.$router)

      return currentCategory?.ownedByMe
        ? currentCategory.meta.id.toString()
        : null
    },
    debounceSearch(): any {
      return debounce((value) => {
        this.search = value
      }, 750)
    },
  },
  methods: {
    copyWidget(widget: WidgetReference) {
      this.$emit('close')

      this.trackCopyWidgetEvent()
      this.trackChooseWidgetEvent(widget.is)

      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG_CLOSE, null)

      const payload: WidgetCopyPayload = {
        widget,
        category: this.myCurrentCategory,
      }

      this.$nextTick(() => {
        this.$eventManager.dispatchEvent(
          WidgetEditorEvents.WIDGET_COPY,
          payload,
        )
      })
    },
    createWidget(widgetType: WidgetTypeDefinition) {
      this.$emit('close')

      this.trackCreateWidgetEvent()
      this.trackChooseWidgetEvent(widgetType.name)

      const widgetName = this.$t(`widgets.${widgetType.name}.name`)
      const configs = {
        is: widgetType.name,
        uid: uuid(),
        title: `${this.$t('common.new.btn')} ${widgetName}`,
      }

      const myId = this.$appContext.authModule.getUserId()
      const widget = {
        configs,
        ...configs, // not a typo. is/uid/title need to be at the root level and inside a config object
        category: this.myCurrentCategory,
        meta: {
          ownedBy: {
            id: myId,
          },
        },
      }

      const widgetStoreModel = toWidgetStoreModel(widget, {
        myId: this.$appContext.authModule.getUserId(),
      })

      this.$router
        .push({
          name: Routes.NEW_WIDGET,
          params: { viewId: widgetStoreModel.uid },
        })
        .then(() => {
          WidgetStoreModel.setNew(widgetStoreModel)
        })
        .catch(omitPushErrors(['NavigationDuplicated']))
    },
    trackChooseWidgetEvent(label: string): void {
      const chooseWidget = ChooseWidget.create({ label })
      this.$analytics.track(chooseWidget)
    },
    trackCopyWidgetEvent(label?: string): void {
      this.$analytics.track(CopyWidget.create())
    },
    trackCreateWidgetEvent(label?: string): void {
      this.$analytics.track(CreateWidget.create())
    },
  },
})
</script>
<style scoped>
.tab-content-wrapper {
  min-height: 404px;
}

.create-copy-header-wrapper {
  display: flex;
  flex-direction: row;
}

.create-copy-header-title {
  width: 75%;
}
</style>
