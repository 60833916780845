<template>
  <v-app>
    <v-navigation-drawer permanent app width="200">
      <v-list dense>
        <template v-for="(view, index) in widgetList">
          <div :key="index">
            <v-list-item :to="toWidgetCategoryRoute(view)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ view.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </div>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <div class="pa-2 ma-4">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>
<script lang="ts">
import Vue from 'vue'
import queryString from 'querystring'
import { WidgetCategoryModel, WidgetStoreModel } from '@/tt-widget-factory'
import { Collection } from '@vuex-orm/core'
import { getCategoryName } from '../lib/DataViewProvider/normalize-data-view-response'
import { Routes } from '../enumRoutes'

export default Vue.extend({
  name: 'StandaloneCategoryView',
  computed: {
    categoryId(): string {
      const id = this.$route.params.categoryId

      if (!id) {
        return null
      }
      return getCategoryName(id)
    },
    category(): WidgetCategoryModel {
      return WidgetCategoryModel.find(this.categoryId)
    },
    widgetList(): Collection<WidgetStoreModel> {
      return WidgetStoreModel.query().where('category', this.categoryId).all()
    },
  },
  methods: {
    toWidgetCategoryRoute(view: WidgetCategoryModel) {
      return {
        name: Routes.WIDGET_CATEGORY_VIEW,
        params: {
          categoryId: this.$route.params.categoryId,
          viewId: view.uid,
        },
        query: queryString.encode(this.$route.query),
      }
    },
  },
})
</script>
