<template>
  <v-list-item
    v-if="item && item.label"
    style="max-width: 400px"
    @click="$emit('click')"
  >
    <v-list-item-action style="display: inline-block">
      <v-icon :color="item.color" v-text="item.icon" />
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
        <template>
          <span style="font-size: 1.2em" v-text="$t(item.label)" />&nbsp;
          <span
            v-if="item.archived"
            v-text="$t('intents.archive.archived')"
          ></span>
        </template>
      </v-list-item-title>
      <v-list-item-subtitle v-text="$t(item.description)">
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action></v-list-item-action>
  </v-list-item>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import { SearchProviderItemInterface } from '@/apps/app.tracktik.insights.studio/extensions/Search/WidgetSearchProvider'

export default Vue.extend({
  name: 'WidgetSearchExtensionInput',
  props: {
    item: {
      required: true,
      type: Object as PropType<SearchProviderItemInterface>,
    },
  },
})
</script>
