<template>
  <div class="fill-height">
    <template v-if="viewMode === 'LIST'">
      <v-list dense data-cy="archive-list">
        <div
          v-for="(item, index) in archiveViews"
          :key="`view.${index}.${item.uid}-list-title`"
          @click="$emit('click', item)"
        >
          <WidgetListTile
            :preview-on-mouse-over="false"
            :item="item"
            :show-action-menu="false"
          />
          <v-divider />
        </div>
      </v-list>
    </template>
    <template v-else>
      <v-row data-cy="archive-grid">
        <v-col
          v-for="(item, index) in archiveViews"
          :key="`view.${index}.${item.uid}-card`"
          :cols="colSize"
        >
          <div @click="$emit('click', item)">
            <WidgetCard :item="item" :show-action-menu="false" />
          </div>
        </v-col>
      </v-row>
    </template>

    <infinite-loading
      identifier="widget-archive-result"
      @infinite.passive="$emit('infinite', $event)"
    >
      <span slot="no-more" />
      <template #no-results>
        <v-sheet class="fill-height text-center transparent">
          <v-icon size="100" color="white ">mdi-delete</v-icon>
          <h1
            style="font-weight: 400; font-size: 20px"
            class="pt-5 pb-3"
            v-text="$t('archive_view.empty_folder')"
          />
          <p
            style="font-weight: 300; font-size: 13px"
            class="pa-0"
            v-text="$t('archive_view.archive_will_show')"
          />
        </v-sheet>
      </template>
    </infinite-loading>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import InfiniteLoading from 'vue-infinite-loading'
import { WidgetArchiveModel } from '@/tt-widget-factory'
import WidgetListTile from './WidgetListTile.vue'
import WidgetCard from './WidgetCard.vue'

export default Vue.extend({
  name: 'WidgetArchiveResults',
  components: {
    WidgetListTile,
    WidgetCard,
    InfiniteLoading,
  },
  props: {
    viewMode: {
      type: String,
      default: 'LIST',
    },
    archiveViews: {
      type: Array as PropType<WidgetArchiveModel[]>,
      required: true,
    },
  },
  computed: {
    colSize(): number {
      return {
        xs: 12,
        sm: 6,
        md: 4,
        lg: 3,
        xl: 2,
      }[this.$vuetify.breakpoint.name]
    },
  },
})
</script>

<style scoped>
.v-icon {
  background: #dbdbdb;
  border-radius: 100%;
  margin-top: 50px;
  padding: 20px;
}
</style>
