import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'

import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { WidgetTagModel } from '@/tt-widget-factory/services/widget-collections/types'

export type WidgetTagInterface = {
  tag: string
  uid: string
  remove: boolean
}

/**
 * Dashboard Create intent
 */
export default class WidgetTag extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_TAG
  }

  /**
   * Trigger a form event
   * @param payload
   */
  run(payload: WidgetTagInterface) {
    if (payload.remove) {
      WidgetTagModel.query()
        .where((item) => {
          return item.uid === payload.uid && item.tag == payload.tag
        })
        .all()
        .forEach((item) => {
          WidgetTagModel.delete(item.$id)
        })
    } else {
      WidgetTagModel.insert({ data: payload })
    }
  }
}
