<template>
  <div>
    <DataDoc></DataDoc>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'

import DataDoc from '../components/DataDoc.vue'

export default Vue.extend({
  name: 'DataModelView',
  components: {
    DataDoc,
  },
})
</script>
