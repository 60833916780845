<template>
  <v-toolbar class="chat-feedback-toolbar" color="transparent" flat height="30">
    <v-btn x-small @click="$emit('analyse')">
      Discuss this data
      <v-icon x-small color="green" right>mdi-robot</v-icon>
    </v-btn>
    <v-spacer/>
    <v-tooltip v-for="button in buttons" :key="button.text" top>
      <template #activator="{ on }">
        <v-btn text icon small v-on="on" @click="button.click">
          <v-icon small>{{ button.icon }}</v-icon>
        </v-btn>
      </template>
      {{ button.text }}
    </v-tooltip>
  </v-toolbar>
</template>
<script lang="ts">
import ChatVue from "@/tt-app-extensions/chat-bot/components/ChatVue";
import {ChatMessage} from "@/tt-app-extensions/chat-bot/types";
import {PropType} from "vue";

export default ChatVue.extend({
  name: 'ChatBotDataViewToolbar',
  props: {
    message: {
      type: Object as PropType<ChatMessage>,
      required: true
    }
  },
  computed: {
    buttons(): any[] {
      return [
        {
          text: 'Copy the query: ' + this.message.data?.tql,
          icon: 'mdi-content-copy',
          color: 'primary',
          click: () => {
            navigator.clipboard.writeText(this.message.data?.tql)
            this.session.snack('Query copied to clipboard')
          },

        },
        {
          icon: 'mdi-thumb-up-outline',
          text: 'This is helpful',
          color: 'success',
          click: () => {
            this.session.like(this.message)
          }
        },
        {
          icon: 'mdi-thumb-down-outline',
          text: 'This answer is not what you were looking for',
          color: 'error',
          click: () => {
            this.session.dislike(this.message)
          }
        },
        {
          icon: 'mdi-heart-outline',
          text: 'Add this answer to your favorite',
          color: 'error',
          click: () => {
            this.session.love(this.message)
          }
        }
      ]
    }
  }
})
</script>
