<template>
  <div>
    <div class="pa-6">
      {{ message }}
    </div>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="grey" @click="close">
        <span v-text="$t('common.cancel.btn')"></span>
      </v-btn>

      <v-btn text data-cy="btn-archive-item" @click="confirm">
        <span class="red--text" v-text="$t('common.archive.btn')"></span>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { Archive } from '@/plugins/o11n'

export default Vue.extend({
  name: 'WidgetArchiveDialog',
  props: {
    message: {
      type: String,
      required: true,
    },
    onConfirm: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  methods: {
    close(): void {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.DIALOG_CLOSE,
      )
    },
    confirm(): void {
      this.onConfirm()
      this.close()
      this.$analytics.track(Archive.create())
    },
  },
})
</script>
