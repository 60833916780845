import Vue, {VueConstructor} from 'vue'
import ChatSession from "@/tt-app-extensions/chat-bot/ChatSession";
import {ChatMessage} from "@/tt-app-extensions/chat-bot/types";

export default (
  Vue as VueConstructor<Vue & { chatSession: () => ChatSession }>).extend({
  inject: ['chatSession'],
  computed: {
    session(): ChatSession {
      return this.chatSession()
    },
    messages(): ChatMessage[] {
      return this.session.messages
    },
    status(): string {
      return this.session.status
    },
    loading(): boolean {
      return this.session.status === 'loading'
    },
    nextQuestions(): string[] {
      return this.session.nextQuestions
    }
  }
})
