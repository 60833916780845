import {
  DraggableInterface,
  DroppableTypes,
  WidgetEditorEvents,
} from '../types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetReference } from '@/tt-widget-factory/types'
import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import { WidgetCategoryInterface } from '@/tt-widget-factory/services/widget-collections/types'
import i18n from '@/plugins/i18n'

/**
 * Dashboard Create intent
 */
export default class DropOnCategory extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.DROP_ON_CATEGORY
  }
  dispatchCategoryActions = (keyMessage, title, name) => {
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.SNACK_INFO, {
      message: i18n.t(keyMessage, [title, name]),
      dismissTime: 600,
    })
    this.appContext.eventManager.dispatchEvent(
      WidgetEditorEvents.CATEGORY_UPDATED,
      {},
    )
  }
  /**
   * Trigger a form event
   * @param payload
   */
  run(dropInterface: DraggableInterface): any {
    if (dropInterface.type == DroppableTypes.WIDGET) {
      const widgetStore = dropInterface.payload
      const widget: WidgetReference = widgetStore.widget
      const category = dropInterface.target
      const categoryId = category.$id.split('|')[1]

      // Can't copy in a category not owned by you
      if (!category.ownedByMe && !!categoryId) {
        this.appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.SNACK_ERROR,
          {
            message: i18n.t('intents.drop_on_category.cannot_copy_to', [
              category.name,
            ]),
            dismissTime: 600,
          },
        )
        return
      }
      // Check if we need to to copy of move
      if (!widgetStore.ownedByMe) {
        // We will copy it, not move it
        this.widgetPersister.copy(widget, dropInterface.target.uid).then(() => {
          this.dispatchCategoryActions(
            'intents.drop_on_category.copied_to',
            widget.title,
            categoryId
              ? category.name
              : i18n.t('custom_widget_provider.my_insights'),
          )
        })
      } else {
        this.widgetPersister.moveTo(widget, category).then(() => {
          this.dispatchCategoryActions(
            'intents.drop_on_category.moved_to',
            widget.title,
            categoryId
              ? category.name
              : i18n.t('custom_widget_provider.my_insights'),
          )
        })
      }

      return true
    }

    if (dropInterface.type == DroppableTypes.CATEGORY) {
      const categoryTo: WidgetCategoryInterface = dropInterface.target
      const category: WidgetCategoryInterface = dropInterface.payload

      // Avoid inceptions
      if (categoryTo.uid == category.uid) {
        return
      }

      if (!categoryTo.ownedByMe || !category.ownedByMe) {
        this.appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.SNACK_ERROR,
          {
            message: i18n.t('intents.drop_on_category.cannot_copy_to', [
              categoryTo.name,
            ]),
            dismissTime: 600,
          },
        )

        return
      }

      this.widgetPersister.moveCategory(category, categoryTo).then(() => {
        this.appContext.eventManager.dispatchEvent(
          LayoutWindowEvent.SNACK_INFO,
          {
            message: i18n.t('intents.drop_on_category.moved_to', [
              category.name,
              categoryTo.name,
            ]),
            dismissTime: 600,
          },
        )
      })
      return true
    }
  }
}
