<template>
  <v-card v-if="isAuthorized">
    <v-toolbar dense flat color="#fafafa">
      <v-app-bar-nav-icon>
        <v-icon color="orange">insert_chart</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="ml-3"
        >Json Schema Demo: Copie paste your JSON schema in the code mirror to
        see the related form</v-toolbar-title
      >
    </v-toolbar>
    <v-container>
      <v-row>
        <v-col>
          <codemirror
            v-model="schemaModel"
            :options="cmOptions"
            style="width: 100%"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!isSchemaEmpty" class="shrink">
          <v-btn class="mb-3" outlined small @click="clear">
            Clear schema
          </v-btn>
        </v-col>
        <v-col v-if="hasError" class="grow">
          <v-btn icon text>
            <v-icon dark color="red" v-text="'mdi-alert'" />
          </v-btn>
          <span
            class="red--text"
            v-text="'Invalid JSON see in the console for more info'"
          />
        </v-col>
      </v-row>
      <v-row class="px-3">
        <v-col>
          <div v-if="!isSchemaEmpty && !hasError">
            <JsonForm
              v-model="model"
              :schema="schema"
              :form-options="formOptions"
              :show-status="true"
            />
          </div>
        </v-col>
        <v-col>
          <WidgetJsonViewer
            v-if="!isSchemaEmpty && model && !hasError"
            :json="model"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
  <v-container v-else>You don't have permission.</v-container>
</template>

<script lang="ts">
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'
import { EditorConfiguration } from 'codemirror'

import metadataProvider, {
  MetaKey,
} from '@/tt-widget-factory/services/metadata-provider'
import WidgetJsonViewer from '@/tt-widget-components/components/WidgetJsonViewer.vue'
import { EmptyValueRule, JsonForm } from '@tracktik/tt-json-schema-form'

import { createEntityViewDefinitions } from '../EntityViewDefinitions'

export default Vue.extend({
  name: 'ApiJsonSchemaDemo',
  components: { JsonForm, WidgetJsonViewer },
  data() {
    const definitions = createEntityViewDefinitions(
      this.$appContext.authModule.getUserPreferences(),
    )

    return {
      formOptions: {
        definitions,
        emptyValues: EmptyValueRule.KEEP,
      },
      model: {},
      editableSchema: {},
      hasError: false,
    }
  },
  computed: {
    cmOptions(): EditorConfiguration {
      return {
        tabSize: 4,
        mode: 'text/javascript',
        theme: 'material',
        lineNumbers: true,
      }
    },
    setSchemaDebounced(): any {
      return debounce((jsonFormSchema) => {
        if (isEmpty(jsonFormSchema)) return
        this.hasError = false

        try {
          const schemaParsed = JSON.parse(jsonFormSchema)

          this.editableSchema = schemaParsed
          this.model = {}
        } catch (error) {
          this.hasError = true
          console.warn(error)
        }
      }, 500)
    },
    isAuthorized(): boolean {
      return this.$appContext.authModule.hasPermission('superuser:*')
    },
    openApiSchema(): any {
      return metadataProvider.get(MetaKey.openApiSchema)
    },
    schemaModel: {
      get(): string {
        return JSON.stringify(this.editableSchema, null, 2)
      },
      set(jsonFormSchema: string) {
        this.setSchemaDebounced(jsonFormSchema)
      },
    },
    isSchemaEmpty(): boolean {
      return isEmpty(this.editableSchema)
    },
    schema(): any {
      return {
        ...this.editableSchema,
        components: this.openApiSchema?.components,
      }
    },
  },
  methods: {
    clear() {
      this.hasError = false
      this.editableSchema = {}
    },
  },
})
</script>
