<template>
  <v-row>
    <v-col
      v-for="index in 12 / cols"
      :key="`skeleton-widget-${index}`"
      :cols="cols"
    >
      <v-skeleton-loader type="card-avatar" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    cols: {
      type: Number,
      required: true,
      validator: (cols) => cols >= 1 && cols <= 12,
    },
  },
}
</script>
