<template>
  <DataViewFetcher :view-id="viewId" @reset="reset">
    <WidgetRender
      v-bind="{
        editMode,
        queryAsFilters,
        controls,
        contextToolbarControls,
        widgetModel,
        isDirty,
        printMode: internalPrintMode,
        presentationMode: presentationMode.get(),
        isSaving,
      }"
      @exit-presentation-mode="presentationMode.exit()"
      @save="editWidget"
      @enter-edit-mode="enterEditMode"
      @exit-edit-mode="confirmExitEditMode"
      @print-mode="setPrintMode"
    />
  </DataViewFetcher>
</template>

<script lang="ts">
import Vue from 'vue'
import { isEqual, omit } from 'lodash'

import {
  WidgetStoreInterface,
  WidgetStoreModel,
  WidgetReference,
} from '@/tt-widget-factory'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetName } from '@/tt-widget-components/lib/names'
import {
  bindBeforeUnload,
  unbindBeforeUnload,
} from '@/helpers/windowEvents/beforeUnload'
import { omitDeep } from '@/helpers/object/omitDeep'
import WidgetRender from '../components/WidgetRender.vue'
import DataViewFetcher from '../components/DataViewFetcher.vue'
import {
  askConfirmationToClear,
  askConfirmationToDiscardChanges,
} from '../lib/on-route-change-utils'
import WidgetPersister from '../lib/WidgetPersister'
import { categoryName } from '../lib/predefined-dashboard-categories'
import { usePresentationMode } from '../lib/RouterUtils'
import { Routes } from '../enumRoutes'

export default Vue.extend({
  name: 'DataViewWidgetView',
  components: { WidgetRender, DataViewFetcher },
  beforeRouteUpdate(to, from, next) {
    // requestConfirmation (and clear store) only when changing `viewId`, not when changing query params (ie: `presentationMode`)
    if (to.params.viewId === from.params.viewId) next()
    else this.requestConfirmation(next)
  },
  beforeRouteLeave(to, from, next) {
    this.requestConfirmation(next)
  },
  props: {
    queryAsFilters: {
      type: Boolean,
      default: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    controls: {
      type: Boolean,
      default: true,
    },
    contextToolbarControls: {
      type: Boolean,
      default: true,
    },
    mountInEditMode: {
      type: Boolean,
      default: false,
    },
    viewId: {
      type: String,
      required: true,
    },
    printMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initalWidgetModel: null as WidgetStoreInterface | null,
      widgetPersister: new WidgetPersister(this.$appContext),
      presentationMode: usePresentationMode(this.$router),
      internalPrintMode: this.printMode,
      editMode: false,
      isSaving: false,
    }
  },
  computed: {
    isPredefinedDashboard(): boolean {
      return categoryName.includes(this.viewId.split('|')[0])
    },
    widgetModel(): WidgetStoreInterface | null {
      return WidgetStoreModel.getSelected()
    },
    isDirty(): boolean {
      if (!this.widgetModel) return false

      // attributes that we don't want to compare to check if a widget/dasbhoard was changed
      const omitAttrNames = ['provider', 'category', 'uid']

      // cleanup the widgets objects before comparing
      const savedWidget = this.cleanUpWidget(
        this.initalWidgetModel?.widget,
        omitAttrNames,
      )
      const currentWidget = this.cleanUpWidget(
        this.widgetModel?.widget,
        omitAttrNames,
      )

      return !isEqual(savedWidget, currentWidget)
    },
  },
  watch: {
    viewId: {
      handler() {
        // TODO FE-591 to be backward compatible, must be removed in the future
        if (this.isPredefinedDashboard) {
          this.$router.replace({
            name: Routes.PREDEFINED_VIEW,
            params: { viewId: this.viewId },
            query: { ...this.$route.query },
          })
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.mountInEditMode) this.enterEditMode()

    bindBeforeUnload(
      this.$tc('widgets.discard_changes_question'),
      () => this.isDirty,
    )
  },
  beforeDestroy() {
    unbindBeforeUnload()
  },
  methods: {
    setPrintMode(printMode: boolean) {
      this.internalPrintMode = printMode
    },
    closeSidePanel() {
      this.$eventManager.dispatchEvent(
        LayoutWindowEvent.SIDE_SHEET_CLOSE,
        () => ({}),
      )
    },
    cleanUpWidget(widget: WidgetReference, omitAttrNames: Array<string>) {
      const isDashboard = widget?.is === WidgetName.DASHBOARD_WIDGET

      if (isDashboard) {
        return omitDeep(widget, omitAttrNames)
      }

      return omit(widget, omitAttrNames)
    },
    reset(widget: WidgetStoreInterface) {
      this.initalWidgetModel = { ...widget }
      WidgetStoreModel.setSelected({ ...widget })
    },
    editWidget(): Promise<void> {
      this.isSaving = true

      return this.widgetPersister
        .editWidget(this.widgetModel)
        .then((response) => {
          this.showSuccessMessage()
          this.reset(response)
        })
        .catch((e) => {
          this.showErrorMessage()
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    showSuccessMessage() {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_SUCCESS,
        { message: this.$t('intents.widget_save.saved') },
      )
    },
    showErrorMessage() {
      this.$appContext.eventManager.dispatchEvent(
        LayoutWindowEvent.SNACK_ERROR,
        { message: this.$t('intents.widget_save.failed_to_save') },
      )
    },
    requestConfirmation(next) {
      return askConfirmationToClear(this.$eventManager, this.isDirty).then(
        () => next(),
        () => next(false),
      )
    },
    confirmExitEditMode() {
      if (this.isDirty)
        askConfirmationToDiscardChanges(this.$eventManager)
          .then(() => this.exitEditMode())
          .catch(() => {})
      else this.exitEditMode()
    },
    enterEditMode() {
      this.editMode = true
    },
    exitEditMode() {
      this.editMode = false
      this.reset(this.initalWidgetModel)
      this.closeSidePanel()
    },
  },
})
</script>
