<template>
  <div>
    <v-menu
      v-model="model"
      :nudge-width="320"
      :close-on-content-click="false"
      bottom
      right
    >
      <template #activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <v-card class="pa-0">
        <v-card-text class="pl-3 pr-3 pt-0 pb-0">
          <v-row>
            <v-col>
              <v-text-field
                v-model="name"
                hide-details
                dense
                :offset-x="30"
                :offset-y="30"
                append-icon="mdi-folder"
                append-icon-color="orange"
                filled
                :label="$t('new_category_icon.label')"
                clearable
                @keydown.enter="create()"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-toolbar flat dense>
          <v-btn text color="grey" @click="model = false">
            <span v-text="$t('common.cancel.btn')"></span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="green" :disabled="!valid" @click="create">
            <span v-text="$t('common.save.btn')"></span>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-menu>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'

import { LayoutWindowEvent } from '@/tt-app-layout'
import WidgetPersister from '@/apps/app.tracktik.insights.studio/lib/WidgetPersister'
import { CreateFolder } from '@/plugins/o11n'

export default Vue.extend({
  name: 'NewCategoryIcon',
  props: {
    parentCategory: String,
    widgetPersister: Object as PropType<WidgetPersister>,
  },
  data() {
    return {
      name: null,
      model: false,
    }
  },
  computed: {
    valid(): boolean {
      return this.name && this.name.trim() != ''
    },
  },
  methods: {
    create() {
      this.$analytics.track(CreateFolder.create())
      if (!this.name) {
        return
      }
      const data = {
        icon: '',
        name: this.name,
        parentCategory: this.parentCategory,
        ownedByMe: true,
      }
      this.model = false
      this.name = ''
      this.widgetPersister
        .createCategory(data)
        .then(() => {
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_INFO, {
            message: this.$t('intents.new_category.created_successfully'),
          })
        })
        .catch((err) => {
          this.$crash.captureException(err)
          this.$eventManager.dispatchEvent(LayoutWindowEvent.SNACK_INFO, {
            message: this.$t('intents.new_category.cant_create'),
          })
        })
    },
  },
})
</script>
