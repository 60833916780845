<template>
  <SidebarTree
    :navigate-to-folder-view="navigateToFolderView"
    :items="[loadMyInsights()]"
    show-tooltip
  />
</template>

<script lang="ts">
import Vue from 'vue'

import { WidgetCategoryInterface } from '@/tt-widget-factory/services/widget-collections/types'
import {
  CategoryNames,
  MY_INSIGHTS_CATEGORY_UID,
  SideBarTreeNode,
} from '../types'

import { getInsightRoute } from '@/apps/app.tracktik.insights.studio/utils'
import { WidgetCategoryModel } from '@/tt-widget-factory'
import { omitPushErrors } from '@/plugins/router'

export default Vue.extend({
  name: 'SidebarTreeMyInsights',
  watch: {},
  methods: {
    navigateToFolderView(event) {
      let route = ''

      if (!event || !event[0]) {
        route = MY_INSIGHTS_CATEGORY_UID
      } else {
        route = event[0]
      }

      this.$router
        .push(getInsightRoute(['explore', route]))
        .catch(omitPushErrors(['NavigationDuplicated']))
    },
    loadMyInsights(): SideBarTreeNode | {} {
      const myInsightsCategoryModel = WidgetCategoryModel.find(
        `${CategoryNames.MY_INSIGHTS}`,
      )

      if (!myInsightsCategoryModel) {
        return {}
      }

      const myInsightsFolder: SideBarTreeNode = {
        id: myInsightsCategoryModel.uid,
        icon: myInsightsCategoryModel.meta?.icon ?? null,
        color: myInsightsCategoryModel.meta?.color ?? 'grey',
        to: getInsightRoute(['explore', CategoryNames.MY_INSIGHTS]),
        name: `${myInsightsCategoryModel.name}`,
        item: myInsightsCategoryModel,
        children: this.loadMyInsightsChildren(myInsightsCategoryModel.uid),
      }

      return myInsightsFolder
    },
    loadMyInsightsChildren(id) {
      return WidgetCategoryModel.query()
        .where('parentCategory', id)
        .all()
        .filter(
          (childCategory: WidgetCategoryInterface) => childCategory.ownedByMe,
        )
        .map((childCategory: WidgetCategoryInterface) => {
          return {
            id: childCategory.uid,
            color: childCategory.meta?.color ?? 'grey',
            to: getInsightRoute(['explore', childCategory.uid]),
            icon: childCategory.meta?.icon ?? null,
            name: childCategory.name,
            item: childCategory,
            children: this.loadMyInsightsChildren(childCategory.uid),
          }
        })
    },
  },
})
</script>
