<template>
  <div style="position: relative">
    <v-toolbar dense short flat>
      <v-btn small icon text circle style="margin-right: 6px" @click="goHome">
        <v-icon small>
          {{ 'mdi-arrow-left' }}
        </v-icon>
      </v-btn>
      <span
        style="font-weight: 500; font-size: 0.8em; line-height: 2em"
        class="pl-1"
        data-cy="archive-title"
        v-text="$t('widget_explorer.archive')"
      />
      <v-spacer />
      <v-btn-toggle v-model="viewMode" flat x-small mandatory>
        <v-btn x-small text value="LIST" data-cy="btn-view-list">
          <v-icon x-small>mdi-format-list-bulleted</v-icon>
        </v-btn>
        <v-btn x-small text value="GRID" data-cy="btn-view-grid">
          <v-icon x-small>mdi-table</v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-toolbar>

    <div class="pl-6 pr-6">
      <WidgetArchiveResults
        :view-mode="viewMode"
        :archive-views="views"
        @click="unarchive($event)"
        @infinite="getArchiveViews($event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

import DataViewProvider from '../lib/DataViewProvider'
import WidgetArchiveResults from '../components/WidgetArchiveResults.vue'
import { WidgetArchiveModel } from '@/tt-widget-factory'
import { WidgetEditorEvents, WidgetViewMode } from '../types'

export default Vue.extend({
  name: 'ArchiveView',
  components: {
    WidgetArchiveResults,
  },
  props: {
    showActionMenu: {
      type: Boolean,
      default: false,
    },
    showSubtitle: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      viewMode: WidgetViewMode.LIST as
        | WidgetViewMode.GRID
        | WidgetViewMode.LIST,
      provider: new DataViewProvider(this.$appContext),
      views: [],
      offset: 0,
    }
  },
  methods: {
    unarchive(item: WidgetArchiveModel) {
      this.$eventManager.dispatchEvent(
        WidgetEditorEvents.WIDGET_UNARCHIVE,
        item,
      )
    },
    async getArchiveViews({ loaded, complete }) {
      const newViews = await this.provider.fetchArchiveViews({
        limit: this.limit,
        offset: this.offset,
      })

      this.views = [...this.views, ...newViews]
      this.offset += this.limit
      if (newViews.length !== 0) loaded()
      if (newViews.length < this.limit) complete()
    },
    goHome() {
      this.$router.push('/')
    },
  },
})
</script>
