import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import WidgetBaseIntent from '@/apps/app.tracktik.insights.studio/intents/WidgetBaseIntent'
import i18n from '@/plugins/i18n'

/**
 * Dashboard Create intent
 */
export default class WidgetCreate extends WidgetBaseIntent {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_CREATE
  }

  /**
   * Trigger a form event
   * @param payload
   */
  async run(payload: any) {
    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
      is: 'WidgetCreateWindow',
      title: i18n.t('intents.widget_create.new_widget'),
      width: 900,
    })
  }
}
