<template>
  <WidgetRender
    :widget-model="widgetModel"
    :presentation-mode="presentationMode.get()"
    :print-mode="printMode"
    @print-mode="printMode = $event"
    @exit-presentation-mode="presentationMode.exit()"
  />
</template>

<script lang="ts">
import Vue from 'vue'

import { WidgetStoreInterface, WidgetStoreModel } from '@/tt-widget-factory'
import WidgetRender from '../components/WidgetRender.vue'
import { usePresentationMode } from '../lib/RouterUtils'

export default Vue.extend({
  name: 'PredefinedWidgetView',
  components: {
    WidgetRender,
  },
  props: {
    viewId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      presentationMode: usePresentationMode(this.$router),
      printMode: false,
    }
  },
  computed: {
    widgetModel(): WidgetStoreInterface {
      return WidgetStoreModel.find(this.viewId)
    },
  },
})
</script>
