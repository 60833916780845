<template>
  <v-card v-if="isAuthorized">
    <v-toolbar dense flat color="#fafafa">
      <v-app-bar-nav-icon>
        <v-icon color="orange">insert_chart</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="ml-3">
        Report Templates Json Schema Demo
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row class="px-3">
        <v-col>
          <EntitySelectorField
            v-model="reportId"
            resource="report-templates"
            solo
          />
          <ProvideCompoundFormManager @ready="setFormManager">
            <div v-if="schema && formManager" class="pa-6">
              <JsonForm
                :schema="schema"
                :form-options="formOptions"
                :show-status="true"
                :value="formManager.getFormValue(formId)"
                @input="formManager.setFormValue(formId, $event)"
                @errors="formManager.setFormErrors(formId, $event)"
                @valid="formManager.setFormErrors(formId, {})"
              />
              <v-btn v-if="isValid" color="success" @click="submit">
                SUBMIT
              </v-btn>
            </div>
          </ProvideCompoundFormManager>
        </v-col>
        <v-col>
          <v-btn
            v-if="hasModelProperties"
            class="mb-3"
            outlined
            small
            @click="clear"
          >
            Clear model
          </v-btn>
          <WidgetJsonViewer
            v-if="schema"
            :json="formManager.getCompoundValue()"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
  <v-container v-else>You don't have permission.</v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import { AppContext } from '@/tt-app-context'
import {
  JsonForm,
  FormOptions,
  EmptyValueRule,
} from '@tracktik/tt-json-schema-form'
import WidgetJsonViewer from '@/tt-widget-components/components/WidgetJsonViewer.vue'
import { createEntityViewDefinitions } from '../EntityViewDefinitions'
import EntitySelectorField from '../components/EntitySelectorField.vue'
import { CompoundFormManager } from '@/tt-app-layout'
import { isEmpty } from 'lodash'
import ProvideCompoundFormManager from '@/tt-app-layout/components/ProvideCompoundFormManager.vue'

export default Vue.extend({
  name: 'ReportTemplatesJsonSchemaDemo',
  components: {
    JsonForm,
    WidgetJsonViewer,
    EntitySelectorField,
    ProvideCompoundFormManager,
  },
  data() {
    const definitions = createEntityViewDefinitions(
      this.$appContext.authModule.getUserPreferences(),
    )
    return {
      reportId: null,
      definitions,
      schema: null,
      formOptions: null as FormOptions,
      callback: null,
      showSubmit: false,
      formManager: null as CompoundFormManager | null,
    }
  },
  computed: {
    isValid(): boolean {
      return !!this.formManager && isEmpty(this.formManager.getCompoundErrors())
    },
    model(): Record<string, unknown> {
      return this.formManager?.getCompoundValue() || {}
    },
    hasModelProperties(): boolean {
      return Object.keys(this.model).length > 0
    },
    isAuthorized(): boolean {
      return this.$appContext.authModule.hasPermission('superuser:*')
    },
    formId(): string {
      return this.$options.name
    },
  },
  watch: {
    reportId: {
      handler(reportId) {
        this.schema = null
        this.formOptions = null
        this.callback = null
        this.clear()
        if (reportId && this.formManager) {
          this.fetchReport(reportId)
        }
      },
      immediate: true,
    },
  },
  methods: {
    setFormManager(formManager: CompoundFormManager): void {
      this.formManager = formManager
      if (this.reportId) {
        this.fetchReport(this.reportId)
      }
    },
    fetchReport(id) {
      const appContext: AppContext = this.$appContext

      const setState = ({ jsonFormSchema }) => {
        console.log(jsonFormSchema)
        const { schema, formOptions, values, callback } = jsonFormSchema
        const { reportTemplate } = values // we clear all null values received -- API should send empty payload instead of null

        const definitions = { ...this.definitions, ...formOptions.definitions }

        this.schema = schema
        this.formOptions = { definitions, emptyValues: EmptyValueRule.REMOVE }
        this.formManager?.setFormValue(this.formId, { reportTemplate })
        this.callback = callback
      }

      appContext.authModule
        .getApi()
        .get('report-templates', id, { extension: ['jsonFormSchema'] })
        .then(setState)
        .catch(console.warn)
    },
    submit() {
      const appContext: AppContext = this.$appContext
      appContext.authModule
        .getApi()
        .create('reports', this.model)
        .then(console.log)
        .catch(console.warn)
    },
    clear() {
      this.formManager?.clearValue()
    },
  },
})
</script>
