<template>
  <TopBarButton text="Chat Bot" icon="mdi-robot"  @click="openChatBot" color="light-green" />
</template>

<script lang="ts">
import Vue from 'vue'
import TopBarButton from '@/tt-app-layout/components/TopBarButton.vue'
import { LayoutWindowEvent } from '@/tt-app-layout'

export default Vue.extend({
  name: 'ChatBotExtension',
  components: { TopBarButton },
  data() {
    return {
      isFullScreen: false,
    }
  },
  computed: {
    isSupported(): boolean {
      return true
    },
  },
  methods: {
    openChatBot() {
      this.$eventManager.dispatchEvent(LayoutWindowEvent.GO_TO, '/chat-bot')
    },
  },
})
</script>
