<template>
  <v-app>
    <ProvideLayoutManager :layout-manager="layoutManager">
      <AppLayoutUtils />
      <v-main class="fill-height">
        <router-view />
      </v-main>
      <AppExtensionSlotFactory container-type="div" slot-name="ANYWHERE" />
    </ProvideLayoutManager>
  </v-app>
</template>
<script lang="ts">
import Vue from 'vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'

export default Vue.extend({
  name: 'StandaloneWidgetView',
  components: {
    ProvideLayoutManager,
  },
  data() {
    return {
      layoutManager: new LayoutManager(this.$eventManager),
    }
  },
  beforeDestroy() {
    if (this.layoutManager) {
      this.layoutManager.destroy()
    }
  },
})
</script>
