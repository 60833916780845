<template>
  <v-list-item
    :class="{ 'drag-cursor': isDraggable }"
    :data-cy="`widget-${item.uid}`"
    @click="$emit('click')"
    @mouseleave="hover = false"
  >
    <v-list-item-icon v-if="isDraggable" class="mr-0">
      <v-icon size="14" v-text="'mdi-drag'" />
    </v-list-item-icon>
    <WidgetMouseOverPreview
      v-if="item"
      v-model="previewOnMouseOver"
      :widget="item.widget"
    >
      <template #default="{ on }">
        <v-list-item-icon class="widget-icon" v-on="on">
          <WidgetIcon :widget-type="item.is" />
        </v-list-item-icon>
      </template>
    </WidgetMouseOverPreview>

    <v-list-item-icon v-if="!item.ownedByMe">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon v-bind="on" size="14" v-on="on" v-text="sharedWithMeIcon" />
        </template>
        <span
          v-if="sharedWithMeIcon == 'mdi-pencil'"
          v-text="$t(`common.role_EDITOR`)"
        />
        <span v-else v-text="$t(`common.role_VIEWER`)" />
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-content @mouseover="hoverItem($event)">
      <v-list-item-title class="text-capitalize">
        {{ item.title }}
      </v-list-item-title>
      <v-list-item-subtitle v-if="displayDescription" class="text-capitalize">
        {{ item.widget.description }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <span
      class="tooltip"
      :class="{
        'show-tooltip': hover,
      }"
    >
      {{ item.title }}
    </span>
    <v-menu v-if="showActionMenu" class="text-right">
      <template #activator="{ on }">
        <v-btn x-small icon text v-on="on">
          <v-icon color="menuColor lighten-1" x-small> more_horiz </v-icon>
        </v-btn>
      </template>
      <v-card class="level1">
        <WidgetActionContextualMenu :disabled="!showActionMenu" :item="item">
          <template #default="{ menu, isLoading }">
            <v-list v-if="isLoading" style="width: 205px">
              <template v-for="index in 3">
                <v-skeleton-loader :key="`loader-${index}`" type="list-item" />
                <v-divider v-if="index < 3" :key="`divider-${index}`" />
              </template>
            </v-list>
            <AppActionMenu v-else :menu="menu" divider />
          </template>
        </WidgetActionContextualMenu>
      </v-card>
    </v-menu>
  </v-list-item>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'

import WidgetActionContextualMenu from './WidgetActionContextualMenu.vue'
import { WidgetStoreModel } from '@/tt-widget-factory'
import { ShareService, UserGroupsModel } from '@/tt-widget-sharable'

export default Vue.extend({
  name: 'WidgetListTile',
  components: {
    WidgetActionContextualMenu,
  },
  props: {
    item: {
      type: Object as PropType<WidgetStoreModel>,
      required: true,
    },
    previewOnMouseOver: {
      type: Boolean,
      default: false,
    },
    showActionMenu: {
      type: Boolean,
      default: true,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
    }
  },
  computed: {
    menuColor(): string {
      return this.$vuetify.theme.dark ? 'white' : '#666'
    },
    displayDescription(): string {
      return this.item.widget && this.item.widget.description
    },
    sharedWithMeIcon(): string {
      const hasWriteAccess = new ShareService(this.$appContext).hasWriteAccess(
        this.item.meta?.aclRule?.grant,
        UserGroupsModel.getters('myUserGroups'),
      )

      return hasWriteAccess ? 'mdi-pencil' : 'mdi-eye'
    },
  },
  methods: {
    hoverItem(e) {
      // Verifies if the title width is bigger than the available space
      // therefore ellipsis applies and only then tooltip is shown
      this.hover = e.target.offsetWidth < e.target.scrollWidth
    },
  },
})
</script>

<style scoped>
.widget-icon {
  margin-right: 12px !important;
}

.tooltip {
  visibility: hidden;
  position: absolute;
  border-radius: 3px;
  background-color: rgba(117, 117, 117, 0.9);
  color: white;
  margin-top: 24px;
  padding: 6px 10px;
  z-index: 2;
  font-size: 14px;
}

.show-tooltip {
  visibility: visible !important;
}
</style>
