<template>
  <div>
    <ChatBotMessage :message="message" :avatar="avatar" :color="color" :name="name" :stream="false"/>
  </div>
</template>
<script lang="ts">
import Vue, {PropType} from 'vue'
import {ChatMessage} from "@/tt-app-extensions/chat-bot/types";
import ChatBotMessage from "@/tt-app-extensions/chat-bot/components/ChatBotMessage.vue";

export default Vue.extend({
  name: 'ChatBotMessageUser',
  components: {
    ChatBotMessage
  },
  data() {
    return {
      color: 'blue',
      name: 'You',
    }
  },
  computed: {
    avatar(): string {
      return this.$auth.getUserProfile()?.avatar ?? null
    },
  },
  props: {
    message: {
      type: Object as PropType<ChatMessage>,
      required: true,
    }
  }
})
</script>
