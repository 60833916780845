import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'

/**
 * Dashboard Create intent
 */
export default class WidgetSave {
  /**
   * Get the event name
   */
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_SAVE_COMPLETE
  }

  run(payload: any): any {}
}
