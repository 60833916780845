<template>
  <drop
    v-if="value"
    class="drag"
    @drop="
      over = false
      trash($event)
    "
    @dragover="over = true"
    @dragleave="over = false"
    @dragexit="over = false"
  >
    <v-card
      flat
      :color="over ? 'blue' : ''"
      :dark="over"
      class="pa-2 text-center elevation-12 trash--can--div"
    >
      <v-icon aligns="center" size="40" color="primary"
        >mdi-trash-can-outline
      </v-icon>
      <div class="heading text-center mt-3">
        {{ $t('drag_trash_can.trash') }}
      </div>
    </v-card>
  </drop>
</template>
<script lang="ts">
import Vue from 'vue'

import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'

export default Vue.extend({
  name: 'DragTrashCan',
  props: {
    value: Boolean,
  },
  data() {
    return {
      over: false,
    }
  },
  methods: {
    trash(payload) {
      this.$eventManager.dispatchEvent(
        WidgetEditorEvents.DROP_IN_TRASH,
        payload,
      )
    },
  },
})
</script>
<style scoped>
.trash--can--div {
  width: 120px;
  position: fixed;
  top: 50px;
  right: 50px;
  border: 1px dashed black;
  z-index: 100;
  font-weight: 300;
}
</style>
