<template>
  <div>
    <div class="pl-6 pr-6 pt-0 pb-4">
      <v-row no-gutters>
        <v-col>
          <v-skeleton-loader v-if="loading" height="100" type="image" />
          <div v-else>
            <div class="pl-4 pt-8 pb-4 font-weight-bold">
              {{ $t('common.select_folder') }}
            </div>
            <v-treeview
              activatable
              :active.sync="activeTreeItem"
              class="treeview"
              hoverable
              :items="treeItems"
              open-all
              @update:active="updateSelected($event)"
            >
              <template #label="{ item }">
                <div class="pl-2">
                  <v-icon
                    small
                    style="opacity: 0.7"
                    :color="item.color"
                    class="mr-3"
                    v-text="item.icon"
                  />
                  <label class="cursorPointer">
                    <span v-text="$t(item.name)" />
                  </label>
                </div>
              </template>
            </v-treeview>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-card-actions class="px-6 pb-6">
      <v-spacer />
      <v-btn
        data-cy="widget-copy-btn"
        class="btn-save-to-folder"
        :disabled="selected == null || loading || submitting"
        :loading="submitting"
        @click="onSubmit()"
      >
        <span v-text="$t('common.save_to_folder.btn')" />
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { omit } from 'lodash'
import uuid from 'uuid/v1'

import { WidgetCategoryModel, WidgetReference } from '@/tt-widget-factory'
import { WidgetStoreModel } from '@/tt-widget-factory/types'
import { MY_INSIGHTS_CATEGORY_UID } from '../types'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { WidgetCopyPayload } from '../intents/WidgetCopy'
import { toWidgetStoreModel } from '../lib/DataViewProvider/normalize-data-view-response'
import { Routes } from '../enumRoutes'
import { omitPushErrors } from '@/plugins/router'
import { updateDashboardUID } from '../lib/update-dashboard-uid'
import { WidgetName } from '@/tt-widget-components/lib/names'

export default Vue.extend({
  name: 'WidgetCopyModal',
  props: {
    payload: Object as PropType<WidgetCopyPayload>,
  },
  data() {
    return {
      activeTreeItem: [] as string[],
      loading: true,
      selected: null as string,
      submitting: false,
      treeItems: [],
    }
  },
  mounted() {
    this.activeTreeItem = this.payload.widget.category
      ? [this.payload.widget.category]
      : []
    this.updateSelected(this.activeTreeItem)
    this.initEditableFolders()
  },
  methods: {
    updateSelected(selectedIds: string[]): void {
      const selectedId = selectedIds[0]
      this.selected = selectedId?.split('|')[1] ?? null
    },
    initEditableFolders(): void {
      const myInsightsCategoryModel = WidgetCategoryModel.find(
        `${MY_INSIGHTS_CATEGORY_UID}`,
      )
      this.treeItems = [
        {
          id: myInsightsCategoryModel.uid,
          icon: myInsightsCategoryModel.icon,
          color: 'grey',
          name: this.$tc(myInsightsCategoryModel.name),
          item: myInsightsCategoryModel,
          children: this.loadFolderChildren(myInsightsCategoryModel.uid),
        },
      ]
      this.loading = false
    },
    // Vuetify types VTreeview's `items` property as any[] and doesn't provide
    // an interface or a type for the items
    loadFolderChildren(id: string): any[] {
      const categories = WidgetCategoryModel.query()
        .where('parentCategory', id)
        .all()

      if (!categories) {
        return []
      }

      return categories
        .filter((childCategory: WidgetCategoryModel) => childCategory.ownedByMe)
        .map((childCategory: WidgetCategoryModel) => {
          return {
            id: childCategory.uid,
            color: 'grey',
            icon: childCategory.icon,
            name: childCategory.name,
            item: childCategory,
            children: this.loadFolderChildren(childCategory.uid),
          }
        })
    },
    onSubmit(): void {
      this.submitting = true
      let copiedWidgetConfigs: Omit<WidgetReference, 'title'> = {
        ...omit(this.payload.widget, ['title']),
        category: this.selected || null,
        uid: uuid(),
      }

      if (this.payload.widget.is === WidgetName.DASHBOARD_WIDGET) {
        copiedWidgetConfigs = updateDashboardUID(
          copiedWidgetConfigs,
          copiedWidgetConfigs.uid,
        )
      }

      const myId = this.$appContext.authModule.getUserId()
      const copiedWidget = {
        ...omit(this.payload.widget, ['title']),
        is: copiedWidgetConfigs.is,
        uid: copiedWidgetConfigs.uid,
        configs: { ...copiedWidgetConfigs },
        category: copiedWidgetConfigs.category,
        meta: {
          ownedBy: {
            id: myId,
          },
        },
      }

      const widgetStoreModel = toWidgetStoreModel(copiedWidget, {
        myId,
      })

      this.$eventManager.dispatchEvent(LayoutWindowEvent.DIALOG_CLOSE, null)
      this.$router
        .push({
          name: Routes.NEW_WIDGET,
          params: { viewId: copiedWidgetConfigs.uid },
        })
        .then(() => {
          WidgetStoreModel.setNew(widgetStoreModel)
        })
        .catch(omitPushErrors(['NavigationDuplicated']))
    },
  },
})
</script>

<style scoped lang="scss">
.v-btn {
  &.btn-save-to-folder {
    background-color: #4e69e9;
    color: white;
  }
}
.treeview {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
