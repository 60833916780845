import { ExtensionInterface, LayoutExtensionSlots } from '@/tt-app-modular'
import WidgetSearchExtensionInput from '@/apps/app.tracktik.insights.studio/extensions/Search/WidgetSearchExtensionInput.vue'
import WidgetSearchExtensionDialog from '@/apps/app.tracktik.insights.studio/extensions/Search/WidgetSearchExtensionDialog.vue'
import Vue from 'vue'

Vue.component('WidgetSearchExtensionDialog', WidgetSearchExtensionDialog)
export default {
  name: 'search-widgets',
  slots: [LayoutExtensionSlots.TOOLBAR_LEFT],
  component: {
    is: WidgetSearchExtensionInput,
  },
} as ExtensionInterface
