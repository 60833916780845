import { Analytics } from '@tracktik/tt-observability/dist/src/analytics'

import { AclRule } from '@/tt-widget-sharable'
import i18n from '@/plugins/i18n'
import { LayoutWindowEvent } from '@/tt-app-layout'
import { Resources } from '@/tt-entity-design/src/types'
import { ShareWidget } from '@/plugins/o11n'
import {
  WidgetCategoryModel,
  WidgetStoreInterface,
  WidgetStoreModel,
  WidgetStoreModelMeta,
} from '@/tt-widget-factory'

import WidgetBaseIntent from './WidgetBaseIntent'
import WidgetPersister from '../lib/WidgetPersister'
import { WidgetEditorEvents } from '../types'

export type MetaType = WidgetStoreModel['meta'] | WidgetCategoryModel['meta']
export type OnSubmitFn = (
  aclRule: AclRule,
  analytics: Analytics,
) => Promise<any>

export default class WidgetShare extends WidgetBaseIntent {
  getEventName(): string {
    return WidgetEditorEvents.WIDGET_SHARE
  }

  run(widget: WidgetStoreInterface): void {
    const widgetPersister = new WidgetPersister(this.appContext)

    const fetchMetadata = (): Promise<WidgetStoreModelMeta> =>
      widgetPersister.fetchWidgetByUid(widget.uid).then((res) => res.meta)

    const trackShareWidget = (analytics: Analytics) => () =>
      analytics.track(ShareWidget.create({ label: widget.is }))

    const updateAclRule: OnSubmitFn = (
      aclRule: AclRule,
      analytics: Analytics,
    ) =>
      widgetPersister
        .shareWidget(+widget.meta.id, aclRule)
        .finally(trackShareWidget(analytics))

    this.appContext.eventManager.dispatchEvent(LayoutWindowEvent.DIALOG, {
      is: 'EntityShareForm',
      title: i18n.t('intents.widget_share.dialog_title'),
      width: 700,
      props: {
        fetchMetadata,
        resourceName: Resources.DATA_VIEWS,
        onSubmit: updateAclRule,
      },
    })
  }
}
