<template>
  <drag
    class="dragging"
    :transfer-data="{ type: 'WIDGET', payload: view }"
    hide-image-html
    @dragstart="onDragStart"
    @click="$emit('click', view)"
  >
    <slot />
    <!-- Drag image below -->
    <template slot="image">
      <div class="drag--container" style="max-width: 300px">
        <WidgetCard
          :key="view.uid"
          dark
          :item="view"
          :show-action-menu="false"
        />
      </div>
    </template>
  </drag>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import WidgetCard from './WidgetCard.vue'
import { WidgetStoreInterface } from '@/tt-widget-factory'
import { ChooseWidgetByDragging } from '@/plugins/o11n'

export default Vue.extend({
  name: 'WidgetSearchResultsDraggable',
  components: {
    WidgetCard,
  },
  props: {
    view: {
      type: Object as PropType<WidgetStoreInterface>,
      required: true,
    },
  },
  methods: {
    onDragStart() {
      this.$analytics.track(
        ChooseWidgetByDragging.create({
          label: this.view.title,
        }),
      )
    },
  },
})
</script>
<style scoped lang="scss">
.dragging {
  ::v-deep .v-list-item {
    cursor: grab !important;
    &:active {
      cursor: grabbing !important;
    }
  }
}
</style>
