<template>
  <div>
    <v-tabs v-model="tabIndex" class="mt-2" left slider-color="orange">
      <v-tab v-text="$t('intents.dashboard_create.tab.new')" />
      <v-tab v-text="$t('intents.dashboard_create.tab.copy')" />
      <v-tab v-text="$t('intents.widget_create.tab.copy_from_code')" />
    </v-tabs>
    <div class="level1 tab-content-wrapper">
      <div v-if="tabIndex === 0" class="py-1">
        <DashboardFormLayoutInput @input="createDashboard($event)" />
      </div>
      <div v-if="tabIndex === 1">
        <div class="pt-3 px-3 create-copy-header-wrapper">
          <div class="create-copy-header-title">
            <v-subheader v-text="$t('common.copy.btn')" />
          </div>
          <v-text-field
            :value="search"
            dense
            append-icon="search"
            clearable
            :placeholder="$t('common.search')"
            @input="debounceSearch"
          />
        </div>
        <div class="px-3">
          <WidgetSearchResults
            :search-provider="searchProvider"
            :show-action-menu="false"
            :include-store-results="true"
            :is-draggable="false"
            view-mode="GRID"
            @click="copyDashboard($event.widget)"
          />
        </div>
      </div>
      <WidgetCopyFromCode
        v-if="tabIndex === 2"
        @click="copyDashboard($event)"
      />
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue'
import uuid from 'uuid/v1'

import WidgetCopyFromCode from '@/tt-widget-components/components/WidgetCopyFromCode.vue'
import { WidgetCollectionSearchProviderInterface } from '@/tt-widget-factory/services/widget-collections/types'
import { WidgetEditorEvents } from '@/apps/app.tracktik.insights.studio/types'
import WidgetPersister from '@/apps/app.tracktik.insights.studio/lib/WidgetPersister'
import { WidgetReference, WidgetStoreModel } from '@/tt-widget-factory/types'
import { DashboardRow, DashboardWidgetModel } from '@/tt-widget-components'
import { ChooseTemplate, CopyDashboard, CreateDashboard } from '@/plugins/o11n'
import { WidgetCopyPayload } from '../intents/WidgetCopy'
import { getCurrentRouteCategory } from '../lib/RouterUtils'
import { toWidgetStoreModel } from '../lib/DataViewProvider/normalize-data-view-response'
import { Routes } from '../enumRoutes'
import { omitPushErrors } from '@/plugins/router'
import debounce from 'lodash/debounce'
import { WidgetName } from '@/tt-widget-components/lib/names'

export default Vue.extend({
  name: 'DashboardCreateWindow',
  components: {
    WidgetCopyFromCode,
  },
  props: {
    widgetPersister: {
      type: Object as PropType<WidgetPersister>,
      default: () => ({}),
    },
  },
  data() {
    return {
      tabIndex: 0,
      search: null,
      model: {
        title: this.$tc('components.widget_context_toolbar.untitled_dashboard'),
        is: 'DashboardWidget',
        rows: [],
      } as DashboardWidgetModel,
    }
  },
  computed: {
    searchProvider(): WidgetCollectionSearchProviderInterface {
      return {
        searchQuery: this.search,
        typeFilter: [WidgetName.DASHBOARD_WIDGET],
      }
    },
    myCurrentCategory(): string | null {
      // we only allow creating widget in a category you own
      const currentCategory = getCurrentRouteCategory(this.$router)

      return currentCategory?.ownedByMe
        ? currentCategory.meta.id.toString()
        : null
    },
    debounceSearch(): any {
      return debounce((value) => {
        this.search = value
      }, 750)
    },
  },
  methods: {
    copyDashboard(dashboard: WidgetReference) {
      this.$emit('close')

      this.trackCopyDashboardEvent(dashboard.category)
      if (dashboard.is === WidgetName.DASHBOARD_WIDGET) {
        this.trackChooseDashboardEvent(dashboard.rows)
      }

      const payload: WidgetCopyPayload = {
        widget: dashboard,
        category: this.myCurrentCategory,
      }

      this.$nextTick(() => {
        this.$eventManager.dispatchEvent(
          WidgetEditorEvents.WIDGET_COPY,
          payload,
        )
      })
    },
    createDashboard(rows: DashboardRow[]) {
      this.trackCreateDashboardEvent()
      this.trackChooseDashboardEvent(rows)

      const myId = this.$appContext.authModule.getUserId()
      const uid = uuid()

      const dashboard = {
        is: this.model.is,
        title: this.model.title,
        status: 'DRAFT',
        description: '',
        uid,
        category: this.myCurrentCategory,
        configs: {
          is: this.model.is,
          uid,
          rows,
          title: this.model.title,
        },
        aclRule: null,
        meta: {
          ownedBy: {
            id: myId,
          },
        },
      }

      const widgetStoreModel = toWidgetStoreModel(dashboard, { myId })

      this.$router
        .push({ name: Routes.NEW_WIDGET, params: { viewId: dashboard.uid } })
        .then(() => {
          WidgetStoreModel.setNew(widgetStoreModel)
        })
        .catch(omitPushErrors(['NavigationDuplicated']))

      this.$emit('close')
    },
    trackChooseDashboardEvent(rows: DashboardRow[]): void {
      // Composes a human-readable label from the rows data
      // E.g.: 'EXTRA_SMALL[3]|EXTRA_LARGE[2]'
      const label = rows
        .map((row) => `${row.size}[${row.columns.length}]`)
        .join('|')
      const event = ChooseTemplate.create({ label })
      this.$analytics.track(event)
    },
    trackCreateDashboardEvent(label?: string): void {
      this.$analytics.track(CreateDashboard.create({ label }))
    },
    trackCopyDashboardEvent(label?: string): void {
      this.$analytics.track(CopyDashboard.create({ label }))
    },
  },
})
</script>
<style scoped>
.tab-content-wrapper {
  min-height: 404px;
}

.create-copy-header-wrapper {
  display: flex;
  flex-direction: row;
}

.create-copy-header-title {
  width: 75%;
  font-weight: bold;
}
</style>
