<template>
  <div class="fill-height">
    <v-card class="pa-4" color="transparent" flat>
      <codemirror v-model="code" :options="cmOptions" style="width: 100%" />
      <v-toolbar flat dense class="toolbar1">
        <div v-if="!isValidWidget || hasError">
          <v-btn icon text>
            <v-icon dark color="red" v-text="'mdi-alert'" />
          </v-btn>
          <span class="red--text" v-text="errorMessage" />
        </div>
        <v-spacer />
        <v-btn v-if="hasEditableCode" small class="mr-2" @click="clear">
          <span v-text="$t('common.clear.btn')" />
        </v-btn>
        <v-btn
          v-if="hasEditableCode"
          :disabled="!isValidWidget"
          dark
          small
          @click="click"
        >
          <span v-text="$t('common.apply.btn')" />
        </v-btn>
      </v-toolbar>
    </v-card>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { WidgetReference } from '@/tt-widget-factory'
import { EditorConfiguration } from 'codemirror'
import { isValidWidget } from '../helpers/is-valid-widget'
import { WidgetName } from '../lib/names'

export default Vue.extend({
  name: 'WidgetCopyFromCode',
  props: {},
  data() {
    return {
      editableCode: {} as WidgetReference,
      hasError: true,
    }
  },
  computed: {
    cmOptions(): EditorConfiguration {
      return {
        tabSize: 2,
        mode: 'text/javascript',
        theme: 'material',
        lineNumbers: true,
      }
    },
    hasEditableCode(): boolean {
      return !isEmpty(this.editableCode)
    },

    isValidWidget(): boolean {
      if (this.hasEditableCode) {
        const { widgetManager } = this.$appContext.widgetServices
        const widget = this.editableCode
        /**
         * search all rows, columns and tabs and check if we have at least one valid widget (aka empty dashboard)
         * @returns whether dashboard has at least one valid widget or not
         */
        if (widget.is === WidgetName.DASHBOARD_WIDGET) {
          return widget?.rows.some((row) =>
            row?.columns?.some((column) => {
              return Array.isArray(column.widgetLookup)
                ? column.widgetLookup.some((widget) =>
                    isValidWidget(widgetManager, widget),
                  )
                : isValidWidget(widgetManager, column.widgetLookup)
            }),
          )
        }

        return isValidWidget(widgetManager, widget)
      }

      return false
    },

    codeDebounced(): any {
      return debounce((jsonFormSchema: string) => {
        if (!isEmpty(jsonFormSchema)) {
          this.hasError = false
          try {
            const schemaParsed: WidgetReference = JSON.parse(jsonFormSchema)

            this.editableCode = schemaParsed
          } catch (error) {
            this.hasError = true

            console.warn(error)
          }
        }
      }, 500)
    },

    code: {
      get(): string {
        return JSON.stringify(this.editableCode, null, 2)
      },
      set(code: string) {
        this.codeDebounced(code)
      },
    },

    errorMessage(): string {
      if (this.hasError) {
        return this.$t('intents.widget_create.json_error')
      }

      return this.$t('intents.widget_create.invalid_widget')
    },
  },

  methods: {
    clear() {
      this.editableCode = {} as WidgetReference
      this.hasError = false
    },
    click() {
      this.$emit('click', this.editableCode)
    },
  },
})
</script>
